import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components-special/Button";
import { useDispatch } from "react-redux";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import InputSmall from "../../components-special/InputSmall";
import toast from "react-hot-toast";
import { createUser } from "../../features/adminSlice";

import copy from "copy-to-clipboard";
import { FaRegCopy, FaSync } from "react-icons/fa";
import { generate } from "random-words";

const initialState = {
  name: "",
  secondName: "",
  login: "",
  // password: "",
  email: "",
};

const AddEmployee = () => {
  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);
  const [isCopy, setIsCopy] = useState("copy");
  const [password, setPassword] = useState("");
  const [ispassword, setIsPassword] = useState("generate");

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const changePasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, secondName, login } = values;

    if (!name || !email || !password || !secondName || !login) {
      toast.error("Введите все значения");
      return;
    }
    dispatch(createUser({ name, email, password, login, secondName }));
  };

  const copyHandler = () => {
    copy("login:" + values.login + "\n" + "password:" + password);
    setIsCopy("copied");
    setTimeout(() => {
      setIsCopy("copy");
    }, 1000);
  };

  const passwordGeneratorHandler = () => {
    setPassword(generate({ minLength: 5, maxLength: 8 }));
    setIsPassword(".....");
    setTimeout(() => {
      setIsPassword("generate");
    }, 300);
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Новый сотрудник</h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="info_1">
              <div className="in">
                <label>Фамилия</label>
                <InputSmall
                  type="text"
                  name="secondName"
                  value={values.secondName}
                  onChange={changeHandler}
                />
              </div>
              <div className="in">
                <label>Имя</label>
                <InputSmall
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="info_1">
              <div className="in">
                <label>Логин (на латинице)</label>
                <InputSmall
                  type="text"
                  name="login"
                  value={values.login.toLowerCase()}
                  onChange={changeHandler}
                />
              </div>
              <div className="in">
                <label>Электронная почта</label>
                <InputSmall
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="info_1">
              <div className="in">
                <label>Пароль</label>
                <InputSmall
                  type="text"
                  name="password"
                  value={password}
                  onChange={changePasswordHandler}
                />
              </div>
              <div className="in2">
                <div className="effect" onClick={passwordGeneratorHandler}>
                  <p>{ispassword}</p>
                  <FaSync />
                </div>
                <div className="effect" onClick={copyHandler}>
                  <p>{isCopy}</p>
                  <FaRegCopy />
                </div>
              </div>
            </div>
            <hr />
            <div className="rights">
              <div className="header">
                <h4>Права доступа</h4>
              </div>
            </div>
            <div className="actions">
              <Button type="submit" text="Создать" />
            </div>
          </form>
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  .in2 {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin: 1rem;
    width: 300px;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .rights {
    display: flex;
    justify-content: center;
  }
  .effect {
    p,
    svg {
      transition: 0.6s;
    }

    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 120px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(15, 15, 15);
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
    .in2 {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .info_1 {
      flex-direction: row;
    }
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AddEmployee;
