import React from "react";
import styled from "styled-components";
import { sidebarOpenHandler } from "../../features/user/userSlise";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/logo_bely_krug.png";

import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaUserCog } from "react-icons/fa";

import Navlinks from "./Navlinks";

const NavBar = () => {
  const { user } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <NavContainer>
      <div className="nav-center">
        <div className="nav-header">
          <div className="logo" onClick={() => navigate("/")}>
            <img src={logo} alt="" />
          </div>

          <button
            type="button"
            className="nav-toggle"
            onClick={() => dispatch(sidebarOpenHandler())}
          >
            <AiOutlineMenu />
          </button>
        </div>
        <Navlinks />
        <div
          className="user-info"
          onClick={() => navigate("/personal-area/user_settings/")}
        >
          <FaUserCog />
          <p>{user.name}</p>
          <p>{user.secondName}</p>
        </div>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav-center {
    /* ! important */
    align-self: stretch;
    width: 90vw;
    margin: 0;
    max-width: var(--max-width);
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    img {
      margin: 1rem;
      width: 70px;
      border-radius: 50%;
    }
  }
  .nav-toggle {
    background: white;
    border: transparent;
    transition: var(--transition2);
    color: var(--blue-0);
    border-radius: 5px;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    :hover {
      scale: calc(1.05);
      box-shadow: var(--shadow-white-1);
    }
    cursor: pointer;
  }
  .name {
    padding: 0.5rem;
    border-radius: 5px;
    border: 2px solid var(--color-3);
    box-shadow: var(--shadow-4);
    cursor: pointer;
    transition: 1s;
    :hover {
    }
    p {
      font-size: 1.3rem;
    }
  }
  svg {
    font-size: 2.3rem;
    color: var(--blue-0);
    transition: var(--transition2);
    :hover {
      color: var(--blue-05);
    }
  }
  .logo {
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
  }
  .user-info {
    display: none;
  }

  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .user-info {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        color: white;
        font-size: 1.3rem;
        margin-right: 0.3rem;
        transition: var(--transition2);
      }
      svg {
        color: white;
        font-size: 1.6rem;
        margin-right: 0.5rem;
        background: transparent;
      }
      :hover {
        p {
          text-decoration: underline;
        }
      }
    }
  }
`;

export default NavBar;
