import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import UserTheme from "../components/userThemes/UserTheme";
import Input2 from "../components-special/Input2";
import { getSongs, getThemeSongs } from "../features/user/userSlise";
import _ from "lodash";

const initialState = {
  search: "",
};

const AllThemesUser = () => {
  const { themes, themeSongs, user } = useSelector((store) => store.user);
  const [values, setValues] = useState(initialState);

  const sortedThemes = _.sortBy(themes, ["name"]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSongs());
  }, []);

  useEffect(() => {
    dispatch(getThemeSongs());
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.AThemes === true && (
        <Wrapper>
          <main>
            <div className="header">
              <h4>Темы</h4>
            </div>
            <div className="search">
              <Input2
                type="text"
                name="search"
                value={values.search.toLowerCase()}
                onChange={changeHandler}
              />
            </div>
            {sortedThemes?.map((theme) => (
              <UserTheme
                key={theme._id}
                name={theme.name}
                id={theme._id}
                description={theme.description}
                anons={theme.anons}
                search={values.search}
              />
            ))}
          </main>
        </Wrapper>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AllThemesUser;
