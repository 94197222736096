import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import UserSong from "../components/userSong/UserSong";
import Input2 from "../components-special/Input2";
import { getSongs } from "../features/user/userSlise";
import _ from "lodash";

const initialState = {
  search: "",
};

const AllExUser = () => {
  const { songs, user } = useSelector((store) => store.user);
  const [values, setValues] = useState(initialState);

  const sortedSongs = _.sortBy(songs, ["name"]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSongs());
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.ASongs && (
        <Wrapper>
          <main>
            <div className="header">
              <h4>Песни и игры</h4>
            </div>
            <div className="search">
              <Input2
                type="text"
                name="search"
                value={values.search.toLowerCase()}
                onChange={changeHandler}
              />
            </div>
            {sortedSongs?.map((song) => (
              <UserSong
                key={song._id}
                name={song.name}
                id={song._id}
                description={song.description}
                search={values.search}
              />
            ))}
          </main>
        </Wrapper>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AllExUser;
