import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import Group from "../components/userGroups/Group";
import Input2 from "../components-special/Input2";
import { getGroups } from "../features/user/userSlise";

const initialState = {
  search: "",
};

const AllGroups = () => {
  const { groups, user } = useSelector((store) => store.user);
  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getGroups());
  // }, [groups._id]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.ALevels === true && (
        <Wrapper>
          <main>
            <div className="header">
              <h4>Все уровни</h4>
            </div>
            <div className="search">
              <Input2
                type="text"
                name="search"
                value={values.search.toLowerCase()}
                onChange={changeHandler}
              />
            </div>
            {groups.map((g) => (
              <Group
                key={g._id}
                name={g.name}
                label={g.label}
                id={g._id}
                description={g.description}
                image={g.image}
                search={values.search}
              />
            ))}
          </main>
        </Wrapper>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AllGroups;
