import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import toast from "react-hot-toast";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { createDirection, getDirection } from "../../features/adminSlice";
import Input2 from "../../components-special/Input2";
import Button from "../../components-special/Button";
import Direction from "../../components/adminGroups/Direction";

const initialState = {
  direction: "",
};

const DirectionPage = () => {
  const { directions } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDirection());
  }, [directions?._id]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = useState(initialState);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!values.direction) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(createDirection({ label: values.direction }));
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Направления</h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="in">
              <Input2
                placeholder="новое направление"
                type="text"
                name="direction"
                value={values.direction}
                onChange={changeHandler}
              />
            </div>
            <div className="actions">
              <Button text="Добавить" />
            </div>
          </form>
          {directions && (
            <div>
              {directions.map((d) => (
                <Direction key={d._id} label={d.label} id={d._id} />
              ))}
            </div>
          )}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .in {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
  input {
    width: 300px;
  }
  .actions {
    margin: 1rem;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 576px) {
    input {
      width: 500px;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default DirectionPage;
