import React from "react";
import styled from "styled-components";
import { FcVideoFile, FcFile } from "react-icons/fc";
import Delete from "../../components-special/Delete";
import { useDispatch } from "react-redux";
import { deleteVideo } from "../../features/adminSlice";
import FileDownload from "js-file-download";
import Axios from "axios";

const { REACT_APP_SPACE } = process.env;

const Video = ({ name, label, groupId, video, id, mimetype }) => {
  const dispatch = useDispatch();

  const downloadHandler = (e) => {
    e.preventDefault();
    Axios({
      url: `${REACT_APP_SPACE}/${video}`,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      FileDownload(res.data, video);
    });
  };

  return (
    <Wrapper>
      <div className="info">
        {mimetype === "application/pdf" && <FcFile onClick={downloadHandler} />}
        {mimetype !== "application/pdf" && <FcVideoFile />}
        <p>{name}</p>
      </div>
      <Delete
        text="удалить"
        onClick={() => dispatch(deleteVideo({ id: id }))}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;

  .info {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    :hover {
      svg {
        scale: 1.1;
      }
    }
    svg {
      font-size: 3rem;
      transition: 0.5s;
    }
  }
  .delete {
    width: 50px;
    margin-right: 2rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Video;
