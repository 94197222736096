import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentSongHandler } from "../../features/user/userSlise";
const UserSong = ({ name, id, search }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const exRedirectHandler = () => {
    dispatch(currentSongHandler(id));
    navigate("/personal-area/song/");
  };
  useEffect(() => {
    const text = name.toLowerCase();
    const searchText = search.toLowerCase();
    setVisible(text.includes(searchText));
  }, [search, name]);

  return (
    <Wrapper onClick={exRedirectHandler}>
      {visible && (
        <div className="group">
          <p className="name">{name}</p>
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  .group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    transition: var(--transition2);
    padding: 0.5rem 1rem;
    cursor: pointer;

    :hover {
      background-color: var(--blue-3);
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .group {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserSong;
