import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getGroups,
  getEx,
  getAim,
  getSongs,
  getThemeSongs,
  getBases,
  getWeeks,
  getAllWL,
  getClasses,
} from "../features/user/userSlise";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import UserCalendar from "../components/UserCalendar/UserCalendar";

const PersonalArea = () => {
  const { groups, user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  useEffect(() => {
    if (user.ALevels === true) {
    }
    dispatch(getGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAim());
  }, [dispatch]);

  useEffect(() => {
    if (groups?.length === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (user?.ASongs === true) {
      dispatch(getSongs());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user?.AThemes === true) {
      dispatch(getThemeSongs());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user?.ABase === true) {
      dispatch(getBases());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWeeks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllWL());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <UserCalendar />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 80vh; */

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default PersonalArea;
