import React from "react";
import styled from "styled-components";

const Input2 = ({ type, placeholder, value, name, onChange }) => {
  return (
    <Wrapper>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />

      <span className="bottom"></span>
      <span className="right"></span>
      <span className="top"></span>
      <span className="left"></span>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* width: 100%; */
  position: relative;
  z-index: 1;
  span {
    position: absolute;
    background-color: var(--blue-0);
    transition: transform 0.5s ease;
  }
  .bottom,
  .top {
    height: 2px;
    left: 0;
    right: 0;
    transform: scaleX(0);
  }
  .left,
  .right {
    width: 2px;
    top: 0;
    bottom: 0;
    transform: scaleY(0);
  }
  .bottom {
    bottom: 0;
    transform-origin: bottom right;
  }
  .right {
    right: 0;
    transform-origin: top right;
  }
  .top {
    top: 0;
    transform-origin: top left;
  }
  .left {
    left: 0;
    transform-origin: bottom left;
  }

  input {
    box-sizing: border-box;
    padding: 1rem;
    border: none;
    background: var(--gray-1);
    height: 35px;
    width: calc(100%);
    font-size: 100%;
    border: 2px solid transparent;
    color: var(--blue-0);
    transition: background-color 0.5s ease-in-out;
    border-radius: 5px;
    z-index: 1;
    ::placeholder {
      color: var(--blue-1);
    }
    :focus {
      outline: none;
    }

    :hover {
      ::placeholder {
        transition: 0.5s;
      }
    }
    :focus ~ .bottom {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
    :focus ~ .right {
      transform-origin: bottom right;
      transform: scaleY(1);
    }
    :focus ~ .top {
      transform-origin: top right;
      transform: scaleX(1);
    }
    :focus ~ .left {
      transform-origin: top left;
      transform: scaleY(1);
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;
export default Input2;
