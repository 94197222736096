import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import Video from "../components/userGroups/Video";
import {
  getVideo,
  getComments,
  currentGroupHandler,
} from "../features/user/userSlise";
import CommentsBase from "../components/Comments/CommentsBase";

import _ from "lodash";

const BasePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentEx, video, bases, currentBase, user } = useSelector(
    (store) => store.user
  );

  const thisBase = bases.find((base) => base._id === currentBase);
  let thisVideo;
  thisVideo = _.filter(video, { groupId: currentBase });

  useEffect(() => {
    if (!currentBase) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (currentBase) {
      dispatch(getVideo({ groupId: currentBase }));
    }
  }, [currentBase]);

  useEffect(() => {
    if (currentEx) {
      dispatch(getComments({ id: currentBase }));
    }
  }, [currentBase]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.ABase === true && (
        <Wrapper>
          {currentBase && (
            <main>
              <div className="header">
                <h4>{thisBase?.name}</h4>
              </div>
              <div className="content">
                <div className="about">
                  <p className="info">Описание</p>
                  <p>{thisBase?.description}</p>
                  <div className="video">
                    {thisVideo.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                        mimetype={v.mimetype}
                      />
                    ))}
                  </div>
                </div>
                <div className="comments">{/* <CommentsBase /> */}</div>
              </div>
            </main>
          )}
        </Wrapper>
      )}
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .label {
    font-size: 1.2rem;
    color: var(--clr-grey-5);
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .about {
    width: 100%;
    padding: 1rem 0;

    p {
      white-space: pre-wrap;
      user-select: none;
    }
  }
  .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .comments {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    margin-top: 3rem;
    width: 100%;
  }
  .logo {
    margin: 1rem;
    img {
      width: 100%;
      max-width: 250px;
    }
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .name {
    cursor: pointer;
    margin-left: 1rem;
  }
  .groups {
    margin-left: 1rem;
  }
  .group {
    margin-left: 1rem;
    color: var(--blue-1);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about {
      width: 600px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1200px) {
    .about {
      width: 800px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .about {
      width: 900px;
    }
    .comments {
      width: 300px;
    }
  }
`;
export default BasePage;
