import React, { useEffect, useLayoutEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import {
  getEx,
  getThemes,
  createClass,
  getClasses,
  deleteClass,
  getGroups,
} from "../../features/adminSlice";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import toast from "react-hot-toast";
import InputSmall from "../../components-special/InputSmall";
import { FaPlus, FaMinus } from "react-icons/fa";
import _ from "lodash";
import Select2 from "../../components-special/Select2";
import Loading2 from "../../components/Loading2";
import Loading from "../../components/Loading";
import Input2 from "../../components-special/Input2";
import ConfirmModal from "../../components-special/ConfirmModal";

const ClassesPage = () => {
  const initialState = {
    name: "",
  };

  const dispatch = useDispatch();

  const { exs, groups, classes, isLoading } = useSelector(
    (store) => store.admin
  );

  const sortedGroup = _.sortBy(groups, [(item) => item.name[1]]);

  const sortedExs = _.sortBy(exs, ["name"]);

  const [searchQuery, setSearchQuery] = useState("");

  const changeHandler2 = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  let filteredExs = sortedExs;
  if (searchQuery) {
    filteredExs = exs.filter((ex) =>
      ex.name.toLowerCase().includes(searchQuery)
    );
  }

  const [values, setValues] = useState(initialState);
  const [groupId, setGroupId] = useState();
  const [tag, setTag] = useState(groups[0]?.name);

  const [list, setList] = useState([]);

  const addListHandler = (data) => {
    setList([...list, data]);
  };

  const removeListHandler = (l) => {
    setList(list.filter((n) => n !== l));
  };

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const currentGroupHandler = (data) => {
    const thisGroup = groups?.find((g) => g.name === data);
    setGroupId(thisGroup?._id);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name } = values;
    if (!name || !groupId || list.length === 0) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        createClass({
          name: values.name,
          list: _.uniq(list),
          groupId: groupId,
        })
      );
      setList([]);
      setValues(initialState);
    }
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentId, setCurrentId] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteClass({ id: currentId }));
    }
  };

  const deleteClassHandler = (_id) => {
    // dispatch(deleteThemeSongs({ id: _id }))
    setIsConfirmModal(true);
    setCurrentId(_id);
  };

  return (
    <div>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        {isLoading && <Loading2 />}
        {!isLoading && (
          <main>
            <div className="header">
              <h4>Уроки</h4>
            </div>
            <form className="content" onSubmit={onSubmit}>
              <div className="in">
                <label> Название нового урока</label>
                <InputSmall
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="in">
                <label>Группа</label>

                <Select2 passState={currentGroupHandler} data={groups} />
              </div>
              <div className="search">
                <Input2
                  type="text"
                  name="search"
                  value={values.search}
                  onChange={changeHandler2}
                />
              </div>
              <div className="content2">
                <div className="list">
                  <p className="info">Список упражнений</p>
                  {!exs && <Loading />}
                  {filteredExs?.map((ex) => (
                    <div className="item" key={ex._id}>
                      <div className="svg-container">
                        <FaPlus onClick={() => addListHandler(ex.name)} />
                      </div>
                      <p>{ex.name}</p>
                    </div>
                  ))}
                </div>
                <div className="added">
                  <p className="info">Урок: {values.name}</p>
                  {_.uniq(list).map((l, index) => (
                    <div key={index} className="added-item">
                      <FaMinus onClick={() => removeListHandler(l)} />
                      <p>{l}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="actions">
                <Button text="Coхранить" type="submit" />
              </div>
            </form>
            <div className="header">
              <h4>Все уроки</h4>
            </div>
            {/* ---------------------------------------------- */}
            <div className="tag-group">
              {sortedGroup?.map((g) => (
                <div
                  key={g._id}
                  className={g.name === tag ? "tag tag-active" : "tag"}
                  onClick={() => setTag(g.name)}
                >
                  <p>{g.name}</p>
                </div>
              ))}
            </div>
            {/* ---------------------------------------------- */}

            <div className="all-classes">
              {classes?.map((c, index) => {
                const thisGroup = groups.find((g) => g._id === c.groupId);
                return (
                  <div key={index}>
                    {thisGroup?.name === tag && (
                      <div className="class" key={c._id}>
                        <p className="class_name">{thisGroup?.name}</p>
                        <p className="class_level">{c.name}</p>
                        <div className="class_ex">
                          {c.list.map((l, index) => (
                            <p key={index}>{l}</p>
                          ))}
                        </div>
                        <div className="actions2">
                          <Delete
                            text="Удалить"
                            // onClick={() => dispatch(deleteClass({ id: c._id }))}
                            onClick={() => deleteClassHandler(c._id)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </main>
        )}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 10px;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .add {
    width: 85vw;
  }
  .content2 {
    display: flex;
    width: 85vw;
    justify-content: space-between;
  }
  .list {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    height: 50vh;
    overflow-y: auto;
    .item {
      display: flex;
      align-items: center;
      margin: 0.5rem;
    }
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--blue-1);
      }
    }
  }
  .added-item {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--purple-2);
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
    div {
      margin: 0 1rem;
    }
  }
  .actions2 {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    margin: 1rem;
    div {
      margin: 0.5rem;
    }
  }
  .all-classes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5rem;
  }
  .class {
    border: 1px solid gray;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px var(--blue-0);
    margin: 1rem;
    width: 90%;
    /* min-height: 300px; */
  }
  .class_name {
    color: var(--blue-0);
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .class_level {
    color: var(--green-2);
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .class_ex {
    margin-left: 1rem;
    p {
      margin: 0.5rem;
    }
  }
  .tag-group {
    display: flex;
    flex-wrap: wrap;
  }
  .tag {
    border: 1px solid var(--green-2);
    padding: 0.5rem 0.8rem;
    margin: 0.5rem;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.6s;
    box-shadow: 0 4px 6px var(--blue-0);
    :hover {
      background-color: var(--green-2);
      border: 2px solid var(--blue-0);
      box-shadow: 0 0px 0px var(--blue-0);
      p {
        color: white;
      }
    }
  }
  .tag-active {
    background-color: var(--green-2);
    border: 2px solid var(--blue-0);
    box-shadow: 0 0px 0px var(--blue-0);
    p {
      color: white;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .class {
      width: 400px;
    }
    .tag-group {
      padding: 0 30px;
    }
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default ClassesPage;
