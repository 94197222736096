import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import {
  getDirection,
  getEx,
  getAim,
  getSongs,
  getThemes,
  getClasses,
  getGroups,
  getWeeks,
  getAllWL,
} from "../../features/adminSlice";
import CalendarAdmin from "../../components/adminCalendar/CalendarAdmin";

const AdminPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDirection());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAim());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSongs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThemes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWeeks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllWL());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Календарь</h4>
          </div>
          <CalendarAdmin />
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AdminPage;
