import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { currentSongHandler } from "../../features/adminSlice";
import { useNavigate } from "react-router-dom";

const Song = ({ id, name, search }) => {
  const { songs } = useSelector((store) => store.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const thisSong = songs.find((song) => song._id === id);

  const [visible, setVisible] = useState(true);

  const exRedirectHandler = () => {
    dispatch(currentSongHandler(id));
    navigate("/a-panel/edit_song/");
  };

  useEffect(() => {
    const text = thisSong.name.toLowerCase();
    const searchText = search.toLowerCase();
    setVisible(text.includes(searchText));
  }, [search, thisSong.name]);

  return (
    <Wrapper onClick={exRedirectHandler}>
      {visible && (
        <div className="group">
          <p className="name">{thisSong.name}</p>
        </div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  .group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    transition: var(--transition2);
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  :hover {
    background-color: var(--blue-3);
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .group {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Song;
