import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { sublinks } from "../../assets/user-data";
import { pageIdHandler } from "../../features/user/userSlise";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  currentGroupHandler,
  currentSongHandler,
  currentThemeHandler,
  currentBaseHandler,
  currentExHandler,
} from "../../features/user/userSlise";
import _ from "lodash";

const { REACT_APP_SPACE } = process.env;

const Submenu = () => {
  const { pageId, groups, songs, themes, bases, user, exs, aims } = useSelector(
    (store) => store.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // __________________________________________________________
  const als4True = _.filter(user.Access, { als4: true });
  // console.log("als4True", als4True);

  const levelsAllowed = _.map(als4True, "levelId");
  // console.log("levelsAllowed", levelsAllowed);

  const aimsAllowed = _.filter(aims, (item) =>
    _.includes(levelsAllowed, item.groupId)
  );

  const listAllowed = _.map(aimsAllowed, "list");

  const arrayAllowed = _.uniq(_.concat(...listAllowed));
  // console.log("arrayAllowed", arrayAllowed);
  // const levelsInAim = _.map(aims, "groupId");
  // console.log("levelsInAim", levelsInAim);

  // const access = [
  //   { id: 1, name: "bob", als4: true },
  //   { id: 2, name: "bob", als4: true },
  //   { id: 3, name: "kop", als4: true },
  //   { id: 4, name: "rob", als4: false },
  //   { id: 5, name: "brob", als4: true },
  // ];

  // const levelsAllowed1 = [1, 22, 4, 5, 6];

  // console.log("aimsAllowed", aimsAllowed);

  // console.log("listAllowed", listAllowed);

  // const la = [
  //   ['cwecer','wewe','trdg'],
  //   ['cwecr','wewe','tdg'],
  //   ['cwewdcer','wefdwe','trdg'],
  //   ['cwecer','wedccewe','trfdfdg'],
  // ]

  // const name = 'rst'
  // const array =['wer','wdt','rst','rty']

  // __________________________________________________________

  const submenuContainer = useRef(null);

  // const currentPage = sublinks.find((item) => item.pageId === pageId);
  const currentPage = pageId;

  const mouseLeaveHandler = (e) => {
    const submenu = submenuContainer.current;
    const { left, right, bottom } = submenu.getBoundingClientRect();
    const { clientX, clientY } = e;

    if (clientX < left - 1 || clientX > right - 1 || clientY > bottom - 1) {
      dispatch(pageIdHandler(null));
    }
  };

  const pageHandler = () => {
    if (pageId === "Упражнения") {
      navigate("/personal-area/all-exercise/");
      dispatch(pageIdHandler(null));
    }
    if (pageId === "Уровни") {
      navigate("/personal-area/groups/");
      dispatch(pageIdHandler(null));
    }
    if (pageId === "Песни и игры") {
      navigate("/personal-area/all_songs/");
      dispatch(pageIdHandler(null));
    }
    if (pageId === "Темы") {
      navigate("/personal-area/all_themes/");
      dispatch(pageIdHandler(null));
    }
    if (pageId === "База знаний") {
      navigate("/personal-area/all_bases/");
      dispatch(pageIdHandler(null));
    }
    if (pageId === "Календарь") {
      navigate("/personal-area");
      dispatch(pageIdHandler(null));
    }
  };

  return (
    <Wrapper>
      <div
        className={currentPage ? "submenu show-submenu" : "submenu"}
        onMouseLeave={mouseLeaveHandler}
        ref={submenuContainer}
      >
        <h4 onClick={pageHandler}>{pageId}</h4>
        <div
          // className="submenu-links"
          // style={{
          //   gridTemplateColumns: groups.length > 3 ? "1fr 1fr" : "1fr",
          // }}
          style={{
            gridTemplateColumns: "1fr",
          }}
        >
          {pageId === "Уровни" && (
            <div>
              {groups?.map((group) => {
                const { _id, name, label, image } = group;
                const currentLevel = user.Access.find(
                  (a) => a?.levelId === _id
                );

                return (
                  <div key={_id}>
                    {currentLevel?.als4 === true && (
                      <div className="link-container">
                        <div className="group">
                          <div className="image-container">
                            <img
                              src={`${REACT_APP_SPACE}/${image}`}
                              alt="img"
                            />
                          </div>
                          <p
                            onClick={() =>
                              dispatch(pageIdHandler(null)) &&
                              dispatch(currentGroupHandler(_id)) &&
                              navigate("/personal-area/group/")
                            }
                            key={_id}
                          >
                            {name}
                          </p>
                        </div>

                        <p className="l">{label}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {pageId === "Упражнения" && (
            <div>
              {exs?.map((song) => {
                const { _id, name } = song;
                const isNameInArray = _.includes(arrayAllowed, name);
                if (isNameInArray) {
                  return (
                    <div key={_id} className="link-container">
                      <div className="group">
                        <p
                          onClick={() =>
                            dispatch(pageIdHandler(null)) &&
                            dispatch(currentExHandler(_id)) &&
                            navigate("/personal-area/exercise/")
                          }
                          key={_id}
                        >
                          {name}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}

          {pageId === "Песни и игры" && (
            <div>
              {songs?.map((song) => {
                const { _id, name } = song;
                return (
                  <div key={_id} className="link-container">
                    <div className="group">
                      <p
                        onClick={() =>
                          dispatch(pageIdHandler(null)) &&
                          dispatch(currentSongHandler(_id)) &&
                          navigate("/personal-area/song/")
                        }
                        key={_id}
                      >
                        {name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {pageId === "Темы" && (
            <div>
              {themes?.map((song) => {
                const { _id, name } = song;
                return (
                  <div key={_id} className="link-container">
                    <div className="group">
                      <p
                        onClick={() =>
                          dispatch(pageIdHandler(null)) &&
                          dispatch(currentThemeHandler(_id)) &&
                          navigate("/personal-area/theme/")
                        }
                        key={_id}
                      >
                        {name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {pageId === "База знаний" && (
            <div>
              {bases?.map((base) => {
                const { _id, name } = base;
                return (
                  <div key={_id} className="link-container">
                    <div className="group">
                      <p
                        onClick={() =>
                          dispatch(pageIdHandler(null)) &&
                          dispatch(currentBaseHandler(_id)) &&
                          navigate("/personal-area/base/")
                        }
                        key={_id}
                      >
                        {name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {pageId === "Календарь" && <div></div>}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .l {
    font-size: 0.8rem;
    color: var(--blue-0);
  }
  .link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .submenu {
    display: none;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .submenu {
      overflow-y: auto;
      margin: auto auto;
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px var(--blue-0);
        border-radius: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: var(--blue-1);
        border-radius: 10px;
      }
      display: block;
      position: fixed;
      top: 7rem;
      width: 70vw;
      background-color: white;
      left: 50%;
      padding: 2rem;
      transform: rotateX(-90deg) translateX(-50%);
      transform-origin: top;
      perspective: 1000px;
      visibility: hidden;
      border-radius: 5px;
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
      z-index: -1;
      box-shadow: var(--shadow-2);
      max-height: 300px;

      h4 {
        margin-bottom: 1rem;
        color: var(--blue-0);
        cursor: pointer;
        :hover {
          text-decoration: underline;
        }
      }
    }
    .show-submenu {
      visibility: visible;
      opacity: 1;
      transform: rotateX(0deg) translate(-50%);
      z-index: 10;
    }
    .submenu-links {
      display: grid;
      row-gap: 0.5rem;
      p {
        display: block;
        color: var(--blue-0);
        font-size: 1.1rem;
        cursor: pointer;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
  .group {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    p {
      color: var(--blue-0);
      :hover {
        text-decoration: underline;
      }
    }
    .image-container {
      width: 50px;
      height: 50px;
      /* border: 1px solid var(--blue-1); */
      display: flex;
      align-items: center;
      justify-content: center;
      /* border-radius: 50%; */

      img {
        width: 40px;
      }
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Submenu;
