import React from "react";
import { nanoid } from "nanoid";

export const sublinks = [
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_users/",
    page: "Сотрудники",
    links: [
      {
        url: "/a-panel/all_users/",
        label: "Все сотрудники",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_employee/",
        label: "Добавить сотрудника",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_groups/",
    page: "Уровни",
    links: [
      {
        url: "/a-panel/all_groups/",
        label: "Все уровни",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_group/",
        label: "Добавить уровень",
        id: nanoid(),
      },
      {
        url: "/a-panel/directions/",
        label: "Добавить направление",
        id: nanoid(),
      },
      {
        url: "/a-panel/classes/",
        label: "Уроки",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_ex/",
    page: "Упражнения",
    links: [
      {
        url: "/a-panel/all_ex/",
        label: "Все упражнения",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_ex/",
        label: "Добавить упражнение",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_songs/",
    page: "Песни и игры",
    links: [
      {
        url: "/a-panel/all_songs/",
        label: "Весь каталог",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_song/",
        label: "Добавить в каталог",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_themes/",
    page: "Темы",
    links: [
      {
        url: "/a-panel/all_themes/",
        label: "Все темы",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_theme/",
        label: "Добавить тему",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/all_bases/",
    page: "База знаний",
    links: [
      {
        url: "/a-panel/all_bases/",
        label: "Все знания",
        id: nanoid(),
      },
      {
        url: "/a-panel/add_base/",
        label: "Добавить знания",
        id: nanoid(),
      },
    ],
  },
  {
    pageId: nanoid(),
    this_link: "/a-panel/",
    page: "Календарь",
    links: [
      {
        url: "/a-panel/",
        label: "Календарь",
        id: nanoid(),
      },
    ],
  },
];
