import customFetch from "../../utils/axios";
import { logOutUser } from "../user/userSlise";

let isUnauthorizedMessageShown = false;

const handleApiError = (error, thunkAPI) => {
  if (error.response?.status === 401) {
    if (!isUnauthorizedMessageShown) {
      isUnauthorizedMessageShown = true;
      thunkAPI.dispatch(logOutUser());
      return thunkAPI.rejectWithValue(
        "Для входа в систему введите логин и пароль"
      );
    }
  } else {
    console.log(error.response?.data.msg);
    return thunkAPI.rejectWithValue(error.response?.data.msg);
  }
};

export const loginUserThunk = async (url, user, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, user);
    return resp.data;
  } catch (error) {
    console.log(error.response.data.msg);
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const getGroupsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getVideoThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};
export const createCommentThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getCommentThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getExThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getAimThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getThemeSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getBasesThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getWeeksThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;

  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getWLAllThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getClassesThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const changePassThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const changeEmailThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};
