import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import Button from "../../components-special/Button";
import { createEx } from "../../features/adminSlice";

const initialState = {
  name: "",
  description: "",
};

const AddEx = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filePickerRef = useRef();

  const [values, setValues] = useState(initialState);
  const [image, setImage] = useState();

  const [previewURL, setpreviewURL] = useState();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const pickedHandler = (e) => {
    let pickedFile;
    if (e.target.files && e.target.files.length === 1) {
      pickedFile = e.target.files[0];
      setImage(pickedFile);
      return;
    }
  };

  useEffect(() => {
    if (!image) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setpreviewURL(fileReader.result);
    };
    fileReader.readAsDataURL(image);
  }, [image]);

  const formData = new FormData();
  formData.append("image", image ? image : "false");
  formData.append("name", values.name);
  formData.append("description", values.description);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(createEx(formData));

      setTimeout(() => {
        navigate("/a-panel/all_ex/");
      }, 1000);
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Новое упражнение</h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="content">
              <div className="info_1">
                <div className="in">
                  <label>Название упражнения</label>
                  <InputSmall
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={changeHandler}
                  />
                </div>
                <div className="in">
                  <div className="effect" onClick={pickImageHandler}>
                    <p>загрузить символ</p>
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".img,.png,.jpeg,.jpg"
                    ref={filePickerRef}
                    onChange={pickedHandler}
                  />
                  <img src={previewURL} alt="" />
                </div>
              </div>
              <div className="text-info">
                <div className="description">
                  <label>Описание</label>
                  <Textarea
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <Button text="Создать упражнение" type="submit" />
            </div>
          </form>
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input,
  .select {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 1rem;
  }
  .instructor {
    height: 200px;
  }

  img {
    margin: 1rem;
    width: 250px;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input,
    .select {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
    }
    .info_1 {
      width: 400px;
    }
    .description {
      width: 500px;
    }
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
  }
  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;

export default AddEx;
