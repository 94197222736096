import React from "react";
import styled from "styled-components";
import Delete from "../../components-special/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteDirection } from "../../features/adminSlice";

const Direction = ({ label, id }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <p>{label}</p>
      <Delete
        text="удалить"
        onClick={() => dispatch(deleteDirection({ id: id }))}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  padding: 0.5rem 1rem;
  @media (min-width: 576px) {
    width: 500px;
  }
  @media (min-width: 768px) {
    width: 700px;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Direction;
