import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { editAimFromList } from "../../features/adminSlice";
import Button from "../../components-special/Button";
import AimAdmin from "../adminAim/AimAdmin";
import { FaMinus } from "react-icons/fa";
import { nanoid } from "nanoid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const EditAim = ({ id, list }) => {
  const { exs } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const [editedList, setEditedList] = useState(list);

  // console.log(editedList);

  const editListHandler = () => {
    dispatch(editAimFromList({ id, editedList }));
  };

  const deleteItemHandler = (index) => {
    const newList = _.filter(editedList, (item, idx) => idx !== index);
    setEditedList(newList);
  };

  const addItemHandler = (data) => {
    setEditedList([...editedList, data]);
  };

  const onDragEndHandler = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newList = Array.from(editedList);
    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);
    setEditedList(newList);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEndHandler}>
        <div className="container">
          <Droppable droppableId={nanoid()}>
            {(provided) => (
              <div
                className="initial-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {provided.placeholder}
                {editedList.map((l, i) => (
                  <Draggable draggableId={`draggable-${i}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className={`added-item ${
                          snapshot.isDragging ? "is-dragging" : ""
                        }`}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                      >
                        <FaMinus onClick={() => deleteItemHandler(i)} />
                        <p>{l}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <div className="in list list-height">
        <p className="info">Список упражнений</p>
        {exs?.map((ex) => (
          <AimAdmin key={ex._id} name={ex.name} passList={addItemHandler} />
        ))}
      </div>
      <div className="actions2">
        <Button text="Сохранить состав цели" onClick={editListHandler} />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  box-sizing: border-box;
  .container {
    height: max-content;
    margin: 0;
    padding: 0;
  }
  .actions2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }
  .initial-list {
    /* padding: 30px; */
  }
  .added-item {
    border: 1px solid gray;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    background: white;
    border: 1px solid var(--blue-0);
    box-shadow: var(--shadow-2);
    &.is-dragging {
      background: var(--blue-3);
      opacity: 0.7;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .initial-list {
      padding: 30px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default EditAim;
