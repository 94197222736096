import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ThisAL from "./ThisAL";

const AccessLevel = ({ name, levelId, passState, ACcess }) => {
  const currentAccess = ACcess?.find((a) => a?.name === name);

  const [als2, setAccessLevels2] = useState();

  const levelHandler2 = (data) => {
    setAccessLevels2(data);
  };

  useEffect(() => {
    passState(als2);
  }, [als2]);

  return (
    <Wrapper>
      <ThisAL
        name={name}
        levelId={levelId}
        passState2={levelHandler2}
        ACcess={ACcess}
        als={currentAccess?.als4}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 1rem;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AccessLevel;
