import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { logOutUser } from "../../features/user/userSlise";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sidebarCloseHandler } from "../../features/user/userSlise";
import Button from "../../components-special/Button";
import { FaUserCog } from "react-icons/fa";
// import {
//   currentGroupHandler,
//   currentSongHandler,
//   currentThemeHandler,
//   currentBaseHandler,
//   currentExHandler,
// } from "../../features/user/userSlise";
import logo from "../../images/logo_bely_krug.png";
import _ from "lodash";

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSidebarOpen, groups, songs, themes, bases, user, aims, exs } =
    useSelector((store) => store.user);

  const als4True = _.filter(user.Access, { als4: true });

  const levelsAllowed = _.map(als4True, "levelId");

  const aimsAllowed = _.filter(aims, (item) =>
    _.includes(levelsAllowed, item.groupId)
  );

  const listAllowed = _.map(aimsAllowed, "list");

  const arrayAllowed = _.uniq(_.concat(...listAllowed));

  return (
    <SidebarContainer>
      <aside className={isSidebarOpen ? `sidebar show-sidebar` : `sidebar`}>
        <div className="sidebar-header">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <button
            className="close-btn"
            type="button"
            onClick={() => dispatch(sidebarCloseHandler())}
          >
            <AiOutlineClose />
          </button>
        </div>
        <ul className="links">
          {user.ALevels === true && (
            <li>
              <p
                onClick={() =>
                  dispatch(sidebarCloseHandler()) &&
                  navigate("/personal-area/groups/")
                }
              >
                Уровни
              </p>
              {/* <div className="sidebar-sublinks">
                {groups?.map((group) => {
                  const { name, label, _id, image } = group;
                  const currentLevel = user.Access.find(
                    (a) => a?.levelId === _id
                  );
                  return (
                    <div key={_id}>
                      {currentLevel?.als4 === true && (
                        <div className="sublink">
                          <p
                            className="sublink-label"
                            onClick={() =>
                              dispatch(sidebarCloseHandler()) &&
                              dispatch(currentGroupHandler(_id)) &&
                              navigate("/personal-area/group/")
                            }
                          >
                            {name}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div> */}
            </li>
          )}
          {user.AExs && (
            <li>
              <p
                onClick={() =>
                  dispatch(sidebarCloseHandler()) &&
                  navigate("/personal-area/all-exercise/")
                }
              >
                Упражнения
              </p>
              {/* <div className="sidebar-sublinks">
                {exs?.map((group) => {
                  const { name, _id } = group;
                  const isNameInArray = _.includes(arrayAllowed, name);
                  if (isNameInArray) {
                    return (
                      <div className="sublink" key={_id}>
                        <p
                          className="sublink-label"
                          onClick={() =>
                            dispatch(sidebarCloseHandler()) &&
                            dispatch(currentExHandler(_id)) &&
                            navigate("/personal-area/exercise/")
                          }
                        >
                          {name}
                        </p>
                      </div>
                    );
                  }
                })}
              </div> */}
            </li>
          )}
          {user.ASongs && (
            <li>
              <p
                onClick={() =>
                  dispatch(sidebarCloseHandler()) &&
                  navigate("/personal-area/all_songs/")
                }
              >
                Песни и игры
              </p>
              {/* <div className="sidebar-sublinks">
                {songs?.map((group) => {
                  const { name, _id } = group;
                  return (
                    <div className="sublink" key={_id}>
                      <p
                        className="sublink-label"
                        onClick={() =>
                          dispatch(sidebarCloseHandler()) &&
                          dispatch(currentSongHandler(_id)) &&
                          navigate("/personal-area/song/")
                        }
                      >
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div> */}
            </li>
          )}
          {user.AThemes && (
            <li>
              <p
                onClick={() =>
                  dispatch(sidebarCloseHandler()) &&
                  navigate("/personal-area/all_themes/")
                }
              >
                Темы
              </p>
              {/* <div className="sidebar-sublinks">
                {themes?.map((group) => {
                  const { name, _id } = group;
                  return (
                    <div className="sublink" key={_id}>
                      <p
                        className="sublink-label"
                        onClick={() =>
                          dispatch(sidebarCloseHandler()) &&
                          dispatch(currentThemeHandler(_id)) &&
                          navigate("/personal-area/theme/")
                        }
                      >
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div> */}
            </li>
          )}
          {user.ABase === true && (
            <li>
              <p
                onClick={() =>
                  dispatch(sidebarCloseHandler()) &&
                  navigate("/personal-area/all_bases/")
                }
              >
                База знаний
              </p>
              {/* <div className="sidebar-sublinks">
                {bases?.map((base) => {
                  const { name, _id } = base;
                  return (
                    <div className="sublink" key={_id}>
                      <p
                        className="sublink-label"
                        onClick={() =>
                          dispatch(sidebarCloseHandler()) &&
                          dispatch(currentBaseHandler(_id)) &&
                          navigate("/personal-area/base/")
                        }
                      >
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div> */}
            </li>
          )}
          <li>
            <p
              onClick={() =>
                dispatch(sidebarCloseHandler()) && navigate("/personal-area/")
              }
            >
              Календарь
            </p>
            {/* <div className="sidebar-sublinks">
              <div className="sublink">
                <p
                  className="sublink-label"
                  onClick={() =>
                    dispatch(sidebarCloseHandler()) &&
                    navigate("/personal-area/")
                  }
                >
                  Календарь
                </p>
              </div>
            </div> */}
          </li>
        </ul>
        <div
          className="user-info"
          onClick={() =>
            dispatch(sidebarCloseHandler()) &&
            navigate("/personal-area/user_settings/")
          }
        >
          <FaUserCog />
          <p>{user.name}</p>
          <p>{user.secondName}</p>
        </div>
        <div className="actions">
          <Button text="Выйти" onClick={() => dispatch(logOutUser())} />
        </div>
      </aside>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  .links {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
    margin: auto;
  }
  li {
    font-size: 2rem;
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    transition: var(--transition2);
    width: 100%;

    p {
      color: white;
    }
  }
  .sidebar-sublinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    .sublink {
      margin-right: 1rem;
      .sublink-label {
        font-size: 1rem;
      }
    }
  }
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: 300px;
  }
  .close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--blue-0);
    transition: var(--transition);
    cursor: pointer;
    position: absolute;
    top: 2.4rem;
    right: 1.5rem;
  }

  .logo {
    justify-self: center;
    /* height: 60px; */
    color: white;
    font-size: 2rem;
    margin-top: 0.5rem;
    cursor: pointer;
    img {
      width: 70px;
    }
  }
  .links {
    margin-bottom: 2rem;
    margin: auto;
  }

  .sidebar {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* transition: var(--transition); */
    opacity: 0;
    /* transform: translate(-100%); */
    background-color: var(--blue-1);
    /* z-index: -1; */
    visibility: hidden;
  }
  .show-sidebar {
    /* transform: translate(0); */
    opacity: 1;
    transition-property: opacity;
    transition-duration: 1s;
    visibility: visible;
    z-index: 3;
  }

  .actions {
    display: flex;
    margin: 1rem;
    justify-content: center;
  }
  svg {
    font-size: 2.3rem;
    color: var(--blue-0);
    transition: var(--transition2);
    background-color: white;
    border-radius: 5px;
    :hover {
      scale: calc(1.05);
      box-shadow: var(--shadow-white-1);
    }
  }
  .sublink-label {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    margin: 1rem;
    cursor: pointer;
    p {
      color: white;
      font-size: 1.3rem;
      margin-right: 0.3rem;
      transition: var(--transition2);
    }
    svg {
      color: white;
      font-size: 1.6rem;
      margin-right: 0.5rem;
      background: transparent;
    }
    :hover {
      p {
        text-decoration: underline;
      }
    }
  }
  img {
    width: 50px;
    margin-right: 1rem;
  }
  @media screen and (min-width: 992px) {
    .sidebar {
      display: none;
    }
  }
`;

export default SideBar;
