import React, { useState } from "react";
import styled from "styled-components";
import InputSmall from "../../components-special/InputSmall";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import { editUser, deleteUser } from "../../features/adminSlice";
import { useDispatch } from "react-redux";

import copy from "copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { generate } from "random-words";
import Access from "../../components/access/Access";
import { motion, AnimatePresence } from "framer-motion";
import ConfirmModal from "../../components-special/ConfirmModal";

const User = ({
  name,
  email,
  login,
  secondName,
  index,
  id,
  ALevels,
  AExs,
  ASongs,
  AThemes,
  ABase,
  ACcess,
}) => {
  const initialState = {
    name: name,
    secondName: secondName,
    login: login,
    // password: "",
    email: email,
  };

  const dispatch = useDispatch();

  const [extra, setExtra] = useState(false);

  const [values, setValues] = useState(initialState);

  const [isCopy, setIsCopy] = useState("copy");
  const [password, setPassword] = useState("");
  const [ispassword, setIsPassword] = useState("generate");

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const changePasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  const copyHandler = () => {
    copy("login:" + values.login + "\n" + "password:" + password);
    setIsCopy("copied");
    setTimeout(() => {
      setIsCopy("copy");
    }, 1000);
  };

  const passwordGeneratorHandler = () => {
    setPassword(generate({ minLength: 5, maxLength: 8 }));
    setIsPassword(".....");
    setTimeout(() => {
      setIsPassword("generate");
    }, 300);
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteUser(id));
    }
  };

  const deleteUserHandler = () => {
    setIsConfirmModal(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, secondName, login } = values;
    if (password.length === 0) {
      dispatch(
        editUser({
          name: name,
          email: email,
          secondName: secondName,
          login: login,
          id: id,
        })
      );
      return;
    } else {
      dispatch(
        editUser({
          name: name,
          email: email,
          secondName: secondName,
          login: login,
          password: password,
          id: id,
        })
      );

      return;
    }
  };

  return (
    <>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Wrapper ALevels>
        <div
          className={extra ? "name name-active" : "name"}
          onClick={() => setExtra(!extra)}
        >
          <p className="label">
            {name} {secondName}
          </p>
          <div className="indicators">
            {ALevels === true ? (
              <div className="circle_green"></div>
            ) : (
              <div className="circle_red"></div>
            )}
            {AExs === true ? (
              <div className="circle_green"></div>
            ) : (
              <div className="circle_red"></div>
            )}
            {ASongs === true ? (
              <div className="circle_green"></div>
            ) : (
              <div className="circle_red"></div>
            )}
            {AThemes === true ? (
              <div className="circle_green"></div>
            ) : (
              <div className="circle_red"></div>
            )}
            {ABase === true ? (
              <div className="circle_green"></div>
            ) : (
              <div className="circle_red"></div>
            )}
          </div>
        </div>
        <AnimatePresence>
          {extra && (
            <motion.div
              className="extra-info"
              variants={{
                hidden: { opacity: 0, y: -80 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                duration: 0.3,
              }}
            >
              <form onSubmit={onSubmit}>
                <div className="info_1">
                  <div className="in">
                    <label>Фамилия</label>
                    <InputSmall
                      type="text"
                      name="secondName"
                      value={values.secondName}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    <label>Имя</label>
                    <InputSmall
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="info_1">
                  <div className="in">
                    <label>Логин (на латинице)</label>
                    <InputSmall
                      type="text"
                      name="login"
                      value={values.login.toLowerCase()}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    <label>Электронная почта</label>
                    <InputSmall
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="info_1">
                  <div className="in">
                    <label>Пароль</label>
                    <InputSmall
                      type="text"
                      name="password"
                      value={password}
                      onChange={changePasswordHandler}
                    />
                  </div>
                  <div className="in2">
                    <div className="effect" onClick={passwordGeneratorHandler}>
                      <p>{ispassword}</p>
                      <FaRegCopy />
                    </div>
                    <div className="effect" onClick={copyHandler}>
                      <p>{isCopy}</p>
                      <FaRegCopy />
                    </div>
                  </div>
                </div>

                <div className="actions">
                  <Button type="submit" text="Редактировать" />
                  <Delete text="удалить" onClick={deleteUserHandler} />
                </div>
              </form>
              <Access
                id={id}
                ALevels={ALevels}
                AExs={AExs}
                ASongs={ASongs}
                AThemes={AThemes}
                ABase={ABase}
                ACcess={ACcess}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    color: var(--clr-grey-1);
  }
  .name {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: var(--transition2);
    .label {
      margin: 0.5rem 1rem;
      font-size: 1.2rem;
    }
    .index {
      margin: 0.5rem 1rem;
    }
    :hover {
      background-color: var(--blue-3);
    }
  }
  .name-active {
    background-color: var(--blue-3);
    justify-content: center;
  }
  .extra-info {
    margin: 1rem;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  .in2 {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin: 1rem;
    width: 300px;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    div {
      margin: 0 1rem;
    }
  }
  .effect {
    p,
    svg {
      transition: 0.6s;
    }

    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 120px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(15, 15, 15);
      }
    }
  }
  .indicators {
    display: flex;
    align-items: center;
    .circle_red {
      width: 15px;
      height: 15px;
      border: 1px solid var(--purple-2);
      background-color: var(--purple-2);
      border-radius: 100%;
      margin: 0.5rem;
    }
    .circle_green {
      width: 15px;
      height: 15px;
      border: 1px solid var(--green-2);
      background-color: var(--green-2);
      border-radius: 100%;
      margin: 0.5rem;
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .info_1 {
      flex-direction: row;
    }
    .in2 {
      width: 350px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default User;
