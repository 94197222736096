import React from "react";
import styled from "styled-components";

const Button = ({ text, onClick, type }) => {
  return (
    <Wrapper>
      <button type={type} onClick={onClick}>
        {text}
      </button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  button {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 35px;
    min-width: 120px;
    position: relative;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: white;
    border: 2px solid var(--blue-0);
    font-size: 1rem;
    overflow: hidden;
    transition: color 0.4s ease-in-out;
    width: max-content;
    color: var(--blue-0);

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      top: 50%;
      left: 50%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--blue-0);
      transform-origin: center;
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
      transition: transform 0.45s ease-in-out;
    }
    :hover {
      cursor: pointer;

      color: white;
    }
    :hover::before {
      transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Button;
