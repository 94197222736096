import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../features/adminSlice";
import User from "../../components/adminUsers/User";
import { motion, AnimatePresence } from "framer-motion";

const AllUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((store) => store.admin);

  useEffect(() => {
    dispatch(getUsers());
  }, [users?._id]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Все сотрудники</h4>
          </div>

          {users?.map((u, index) => (
            <User
              key={u._id}
              name={u.name}
              email={u.email}
              login={u.login}
              secondName={u.secondName}
              index={index + 1}
              id={u._id}
              ALevels={u.ALevels}
              AExs={u.AExs}
              ASongs={u.ASongs}
              AThemes={u.AThemes}
              ABase={u.ABase}
              ACcess={u.Access}
            />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AllUsers;
