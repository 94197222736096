import React from "react";
import styled from "styled-components";

const Loading = () => {
  return (
    <Wrapper>
      <div className="loader">
        <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background-color: white; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    position: relative;
  }

  .outer,
  .middle,
  .inner {
    border: 3px solid transparent;
    border-top-color: var(--blue-1);
    border-right-color: var(--blue-1);
    border-radius: 50%;
    position: absolute;
    top: 100px;
    left: 50%;
  }
  .outer {
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    animation: spin 2s linear infinite;
  }
  .middle {
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    animation: spin 1.75s linear reverse infinite;
  }
  .inner {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
  @media (min-width: 1540px) {
  }
`;

export default Loading;
