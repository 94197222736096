import { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import moment from "moment";

const Comment = ({ login, comment, createdAt }) => {
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      // inline: "nearest",
    });
  }, []);

  return (
    <Wrapper ref={scrollRef}>
      <div className="msg">
        <p className="login">{login}: </p>
        <p className="comment"> {comment}</p>
      </div>
      <div>
        <p className="date">{moment(createdAt).format("lll")}</p>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .msg {
    display: flex;
  }
  .login {
    color: var(--blue-0);
  }
  .comment {
    font-style: italic;
    word-break: break-all;
  }
  .date {
    font-size: 0.8rem;
    color: var(--green-1);
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Comment;
