import React, { useState } from "react";
import styled from "styled-components";
import Delete from "./Delete";
import Button from "./Button";
// import alert from "../images/alert-icon-1584.png";
const ConfirmModal = ({ passState }) => {
  const confirmHandler = () => {
    passState(true);
  };

  const cancelHandler = () => {
    passState(false);
  };
  return (
    <Wrapper>
      <div className="modal">
        <img className="img1" src={alert} alt="alert" />
        <img className="img2" src={alert} alt="alert" />
        <img className="img3" src={alert} alt="alert" />
        <div className="text">Подтвердить удаление</div>
        <div className="actions3">
          <Delete text="Удалить" onClick={confirmHandler} />
          <Button text="Отмена" onClick={cancelHandler} />
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 20, 20, 0.5);
  transition: 1s;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  z-index: 999;
  opacity: 1;
  border: 3px solid red;

  .modal {
    width: 90vw;
    height: 200px;
    background-color: white;
    border-radius: 15px;
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .text {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  .actions3 {
    display: flex;
    position: absolute;
    bottom: 20px;
    div {
      margin: 10px;
    }
  }
  .img1 {
    width: 30%;
    position: absolute;
    left: 10px;
    top: 10px;
    opacity: 0.1;
    transform: rotate(-20deg);
  }
  .img2 {
    width: 25%;
    position: absolute;
    left: 150px;
    top: 0px;
    opacity: 0.15;
    transform: rotate(20deg);
  }
  .img3 {
    width: 35%;
    position: absolute;
    right: 10px;
    top: -40px;
    opacity: 0.2;
    transform: rotate(-40deg);
  }
  @media (min-width: 576px) {
    .modal {
      width: 400px;
      height: 150px;
      background-color: white;
      border-radius: 15px;
      overflow: auto;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ConfirmModal;
