import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginAdminThunk,
  remindAdminThunk,
  createUserThunk,
  getusersThunk,
  editUserThunk,
  createGroupThunk,
  getGroupsThunk,
  uploadVideoThunk,
  getVideoThunk,
  updateGroupThunk,
  deleteVideoThunk,
  deleteGroupThunk,
  createDirectionThunk,
  getDirectionThunk,
  deleteDirectionThunk,
  createExThunk,
  getExThunk,
  updateExThunk,
  deleteExThunk,
  createAimThunk,
  getAimThunk,
  deleteAimThunk,
  createSongThunk,
  getSongsThunk,
  editSongThunk,
  deleteSongThunk,
  createThemeThunk,
  getThemeThunk,
  editThemeThunk,
  deleteThemeThunk,
  createThemeSongsThunk,
  getThemeSongsThunk,
  deleteThemeSongsThunk,
  createBaseThunk,
  getBasesThunk,
  editBaseThunk,
  deleteBaseThunk,
  createWeekThunk,
  getWeeksThunk,
  editWeekThunk,
  deleteWeekThunk,
  createClassThunk,
  getClassesThunk,
  deleteClassThunk,
  createWeekLevelThunk,
  getWLThunk,
  getAllWLThunk,
  updateAccessThunk,
  updateLevelsAccessThunk,
  deleteUserThunk,
  addWholeYearThunk,
  add10WeeksThunk,
  editAimThunk,
  editAimFromListThunk,
} from "./admin-thunk";
import toast from "react-hot-toast";
import {
  addAdminToLocalStorage,
  removeAdminFromLocalStorage,
  getAdminFromLocalStorage,
  addAdminTokenToLocalStorage,
  removeAdminTokenFromLocalStorage,
  getAdminTokenFromLocalStorage,
} from "../utils/localStorage";

const initialState = {
  isLoading: false,
  admin: getAdminFromLocalStorage(),
  token: getAdminTokenFromLocalStorage(),
  // admin: localStorage.getItem("admin"),
  // token: localStorage.getItem("adminToken"),
  currentGroup: "",
  currentEx: "",
  currentSong: "",
  currentTheme: "",
  currentBase: "",
  currentWeek: "",
  users: [],
  groups: [],
  videos: [],
  directions: [],
  exs: [],
  aims: [],
  songs: [],
  themes: [],
  themeSongs: [],
  bases: [],
  weeks: [],
  classes: [],
  weekLevel: [],
  weekLevelAll: [],
  currentID: "",
  isExtra: false,
  cursorPosition: {},
  levelIds: [],
};

const showWarningToast = (payload) => {
  toast(payload, {
    icon: "⚠️",
    style: {
      borderRadius: "10px",
      background: "white",
      color: "var(--blue-0)",
    },
  });
};

export const loginAdmin = createAsyncThunk(
  "admin/loginAdmin",
  async (admin, thunkAPI) => {
    return loginAdminThunk(`/auth_admin/login/`, admin, thunkAPI);
  }
);

export const remindAdmin = createAsyncThunk(
  "admin/remindAdmin",
  async (admin, thunkAPI) => {
    return remindAdminThunk(`/auth_admin/remind_pass/`, admin, thunkAPI);
  }
);

export const createUser = createAsyncThunk(
  "admin/createUser",
  async (info, thunkAPI) => {
    return createUserThunk(`/admin/create_user/`, info, thunkAPI);
  }
);

export const getUsers = createAsyncThunk(
  "admin/getUsers",
  async (info, thunkAPI) => {
    return getusersThunk(`/admin/get_users/`, info, thunkAPI);
  }
);

export const editUser = createAsyncThunk(
  "admin/editUser",
  async (info, thunkAPI) => {
    return editUserThunk(`/admin/edit_user/`, info, thunkAPI);
  }
);

export const createGroup = createAsyncThunk(
  "admin/createGroup",
  async (info, thunkAPI) => {
    return createGroupThunk(`/admin/create_group/`, info, thunkAPI);
  }
);

export const getGroups = createAsyncThunk(
  "admin/get_groups",
  async (info, thunkAPI) => {
    return getGroupsThunk(`/admin/get_groups/`, info, thunkAPI);
  }
);

export const uploadVideo = createAsyncThunk(
  "admin/upload_video",
  async (info, thunkAPI) => {
    return uploadVideoThunk(`/admin/upload_video/`, info, thunkAPI);
  }
);

export const getVideo = createAsyncThunk(
  "admin/get_video",
  async (info, thunkAPI) => {
    return getVideoThunk(`/admin/get_video/${info.groupId}`, info, thunkAPI);
  }
);

export const updateGroup = createAsyncThunk(
  "admin/update_group",
  async (info, thunkAPI) => {
    return updateGroupThunk(`/admin/update_group/`, info, thunkAPI);
  }
);

export const deleteVideo = createAsyncThunk(
  "admin/delete_video",
  async (info, thunkAPI) => {
    return deleteVideoThunk(`/admin/delete_video/${info.id}`, info, thunkAPI);
  }
);

export const deleteGroup = createAsyncThunk(
  "admin/delete_group",
  async (info, thunkAPI) => {
    return deleteGroupThunk(`/admin/delete_group/${info.id}`, info, thunkAPI);
  }
);

export const createDirection = createAsyncThunk(
  "admin/create_direction",
  async (info, thunkAPI) => {
    return createDirectionThunk(`/admin/create_direction`, info, thunkAPI);
  }
);

export const getDirection = createAsyncThunk(
  "admin/get_direction",
  async (info, thunkAPI) => {
    return getDirectionThunk(`/admin/get_direction`, info, thunkAPI);
  }
);

export const deleteDirection = createAsyncThunk(
  "admin/delete_direction",
  async (info, thunkAPI) => {
    return deleteDirectionThunk(
      `/admin/delete_direction/${info.id}`,
      info,
      thunkAPI
    );
  }
);

export const createEx = createAsyncThunk(
  "admin/create_ex",
  async (info, thunkAPI) => {
    return createExThunk(`/admin/create_ex`, info, thunkAPI);
  }
);

export const getEx = createAsyncThunk(
  "admin/get_ex",
  async (info, thunkAPI) => {
    return getExThunk(`/admin/get_ex`, info, thunkAPI);
  }
);

export const updateEx = createAsyncThunk(
  "admin/update_ex",
  async (info, thunkAPI) => {
    return updateExThunk(`/admin/update_ex`, info, thunkAPI);
  }
);

export const deleteEx = createAsyncThunk(
  "admin/delete_ex",
  async (info, thunkAPI) => {
    return deleteExThunk(`/admin/delete_ex/${info.id}`, info, thunkAPI);
  }
);

export const createAim = createAsyncThunk(
  "admin/create_aim",
  async (info, thunkAPI) => {
    return createAimThunk(`/admin/create_aim/`, info, thunkAPI);
  }
);

export const getAim = createAsyncThunk(
  "admin/get_aim",
  async (info, thunkAPI) => {
    return getAimThunk(`/admin/get_aim/`, info, thunkAPI);
  }
);

export const deleteAim = createAsyncThunk(
  "admin/delete_aim",
  async (info, thunkAPI) => {
    return deleteAimThunk(`/admin/delete_aim/${info.id}`, info, thunkAPI);
  }
);

export const createSong = createAsyncThunk(
  "admin/add_song",
  async (info, thunkAPI) => {
    return createSongThunk(`/admin/create_song/`, info, thunkAPI);
  }
);

export const getSongs = createAsyncThunk(
  "admin/get_songs",
  async (info, thunkAPI) => {
    return getSongsThunk(`/admin/get_songs/`, info, thunkAPI);
  }
);

export const editSong = createAsyncThunk(
  "admin/edit_song",
  async (info, thunkAPI) => {
    return editSongThunk(`/admin/edit_song/`, info, thunkAPI);
  }
);

export const deleteSong = createAsyncThunk(
  "admin/delete_song",
  async (info, thunkAPI) => {
    return deleteSongThunk(`/admin/delete_song/${info.id}`, info, thunkAPI);
  }
);

export const createTheme = createAsyncThunk(
  "admin/create_theme",
  async (info, thunkAPI) => {
    return createThemeThunk(`/admin/create_theme/`, info, thunkAPI);
  }
);

export const getThemes = createAsyncThunk(
  "admin/get_themes",
  async (info, thunkAPI) => {
    return getThemeThunk(`/admin/get_themes/`, info, thunkAPI);
  }
);

export const editTheme = createAsyncThunk(
  "admin/edit_theme",
  async (info, thunkAPI) => {
    return editThemeThunk(`/admin/edit_theme/`, info, thunkAPI);
  }
);

export const deleteTheme = createAsyncThunk(
  "admin/delete_theme",
  async (info, thunkAPI) => {
    return deleteThemeThunk(`/admin/delete_theme/${info.id}`, info, thunkAPI);
  }
);

export const createThemeSongs = createAsyncThunk(
  "admin/create_themeSongs",
  async (info, thunkAPI) => {
    return createThemeSongsThunk(`/admin/create_themesongs`, info, thunkAPI);
  }
);

export const getThemeSongs = createAsyncThunk(
  "admin/get_themeSongs",
  async (info, thunkAPI) => {
    return getThemeSongsThunk(`/admin/get_themesongs`, info, thunkAPI);
  }
);

export const deleteThemeSongs = createAsyncThunk(
  "admin/delete_themeSongs",
  async (info, thunkAPI) => {
    return deleteThemeSongsThunk(
      `/admin/delete_themesongs/${info.id}`,
      info,
      thunkAPI
    );
  }
);

export const createBase = createAsyncThunk(
  "admin/create_base",
  async (info, thunkAPI) => {
    return createBaseThunk(`/admin/create_base`, info, thunkAPI);
  }
);

export const getBases = createAsyncThunk(
  "admin/get_bases",
  async (info, thunkAPI) => {
    return getBasesThunk(`/admin/get_bases`, info, thunkAPI);
  }
);

export const editBase = createAsyncThunk(
  "admin/edit_base",
  async (info, thunkAPI) => {
    return editBaseThunk(`/admin/update_base`, info, thunkAPI);
  }
);

export const deleteBase = createAsyncThunk(
  "admin/delete_Base",
  async (info, thunkAPI) => {
    return deleteBaseThunk(`/admin/delete_base/${info.id}`, info, thunkAPI);
  }
);

export const createWeek = createAsyncThunk(
  "admin/create_week",
  async (info, thunkAPI) => {
    return createWeekThunk(`/admin/create_week`, info, thunkAPI);
  }
);

export const getWeeks = createAsyncThunk(
  "admin/get_weeks",
  async (info, thunkAPI) => {
    return getWeeksThunk(`/admin/get_weeks`, info, thunkAPI);
  }
);

export const editWeek = createAsyncThunk(
  "admin/edit_week",
  async (info, thunkAPI) => {
    return editWeekThunk(`/admin/edit_week`, info, thunkAPI);
  }
);

export const deleteWeek = createAsyncThunk(
  "admin/delete_Week",
  async (info, thunkAPI) => {
    return deleteWeekThunk(`/admin/delete_week/${info.weekId}`, info, thunkAPI);
  }
);

export const createClass = createAsyncThunk(
  "admin/create_class",
  async (info, thunkAPI) => {
    return createClassThunk(`/admin/create_class`, info, thunkAPI);
  }
);

export const getClasses = createAsyncThunk(
  "admin/get_classes",
  async (info, thunkAPI) => {
    return getClassesThunk(`/admin/get_classes`, info, thunkAPI);
  }
);

export const deleteClass = createAsyncThunk(
  "admin/delete_class",
  async (info, thunkAPI) => {
    return deleteClassThunk(`/admin/delete_class/${info.id}`, info, thunkAPI);
  }
);

export const createWeekLevel = createAsyncThunk(
  "admin/createWeekLevel",
  async (info, thunkAPI) => {
    return createWeekLevelThunk(`/admin/create_weeklevel`, info, thunkAPI);
  }
);

export const getWL = createAsyncThunk(
  "admin/getWeekLevel",
  async (info, thunkAPI) => {
    return getWLThunk(`/admin/get_weeklevel`, info, thunkAPI);
  }
);

export const getAllWL = createAsyncThunk(
  "admin/getAllWeekLevel",
  async (info, thunkAPI) => {
    return getAllWLThunk(`/admin/getAll_weeklevel`, info, thunkAPI);
  }
);

export const updateAccess = createAsyncThunk(
  "admin/updateAccess",
  async (info, thunkAPI) => {
    return updateAccessThunk(`/admin/updateAccess`, info, thunkAPI);
  }
);

export const updateLevelsAccess = createAsyncThunk(
  "admin/updateLevelsAccess",
  async (info, thunkAPI) => {
    return updateLevelsAccessThunk(`/admin/updateLevelsAccess`, info, thunkAPI);
  }
);

export const deleteUser = createAsyncThunk(
  "admin/deleteuser",
  async (info, thunkAPI) => {
    return deleteUserThunk(`/admin/deleteuser/${info}`, info, thunkAPI);
  }
);

export const addWholeYear = createAsyncThunk(
  "admin/addwholeyear",
  async (info, thunkAPI) => {
    return addWholeYearThunk(`/admin/addwholeyear/`, info, thunkAPI);
  }
);

export const add10Weeks = createAsyncThunk(
  "admin/add10weeks",
  async (info, thunkAPI) => {
    return add10WeeksThunk(`/admin/add10weeks/`, info, thunkAPI);
  }
);

export const editAim = createAsyncThunk(
  "admin/editAim",
  async (info, thunkAPI) => {
    return editAimThunk(`/admin/editAim/`, info, thunkAPI);
  }
);

export const editAimFromList = createAsyncThunk(
  "admin/editAimFromList",
  async (info, thunkAPI) => {
    console.log(info);
    return editAimFromListThunk(`/admin/editAimFromList`, info, thunkAPI);
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    logOutAdmin: (state) => {
      state.admin = null;
      removeAdminFromLocalStorage();
      removeAdminTokenFromLocalStorage();
      state.users = [];
      state.groups = [];
      state.exs = [];
      state.directions = [];
      state.aims = [];
      state.songs = [];
      state.themes = [];
      state.weeks = [];
      state.classes = [];
      state.weekLevelAll = [];
      state.songs = [];
      state.currentGroup = "";
      state.currentEx = "";
      state.currentSong = "";
      state.currentTheme = "";
      state.currentBase = "";
      state.currentWeek = "";
      state.currentID = "";
      state.cursorPosition = {};
      state.isExtra = "false";

      // toast.success(`Пока !`);
    },

    currentIDHandler: (state, { payload }) => {
      state.currentID = payload;
    },
    currentGroupHandler: (state, { payload }) => {
      state.currentGroup = payload;
    },
    currentExHandler: (state, { payload }) => {
      state.currentEx = payload;
    },
    currentSongHandler: (state, { payload }) => {
      state.currentSong = payload;
    },
    currentThemeHandler: (state, { payload }) => {
      state.currentTheme = payload;
    },
    currentBaseHandler: (state, { payload }) => {
      state.currentBase = payload;
    },
    currentWeekHandler: (state, { payload }) => {
      state.currentWeek = payload;
    },
    currentClassHandler: (state, { payload }) => {
      state.isExtra = payload;
    },
    cursorPositionHandler: (state, { payload }) => {
      state.cursorPosition = payload;
    },
    LevelIdsHandler: (state, { payload }) => {
      state.levelIds = payload;
    },
  },
  extraReducers: (builder) => {
    // loginAdmin
    builder.addCase(loginAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { admin, token } = payload;
      state.admin = admin;
      state.token = token;
      addAdminToLocalStorage(admin);
      addAdminTokenToLocalStorage(token);
      toast.success(`Привет Админ !`);
    });
    builder.addCase(loginAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // remindAdmin
    builder.addCase(remindAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(remindAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload.msg);
    });
    builder.addCase(remindAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createUser
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users.push(payload.user);
      toast.success(`Создан пользователь ${payload.user.login}`);
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getUsers
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;

      // toast.success(`Создан пользователь ${payload.user.login}`);
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editUser
    builder.addCase(editUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success(`Сохранено!`);
    });
    builder.addCase(editUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createGroup
    builder.addCase(createGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success(`Сохранено!`);
    });
    builder.addCase(createGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getGroups
    builder.addCase(getGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload.groups;
      // state.exs = payload.exs;
    });
    builder.addCase(getGroups.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // uploadVideo
    builder.addCase(uploadVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadVideo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.videos.push(payload);
      // toast.success(`Сохранено!`);
    });
    builder.addCase(uploadVideo.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });
    // getVideo
    builder.addCase(getVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVideo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.videos = payload;
      // toast.success(`Сохранено!`);
    });
    builder.addCase(getVideo.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // updateGroup
    builder.addCase(updateGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
      toast.success(`Изменения сохранены !`);
      console.log(payload);
    });
    builder.addCase(updateGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteVideo
    builder.addCase(deleteVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVideo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Файл удален !");
      state.videos = payload;
    });
    builder.addCase(deleteVideo.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteGroup
    builder.addCase(deleteGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
      toast.success("Уровень удален !");
    });
    builder.addCase(deleteGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createDirection
    builder.addCase(createDirection.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createDirection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.directions.push(payload);
    });
    builder.addCase(createDirection.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getDirection
    builder.addCase(getDirection.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDirection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.directions = payload;
    });
    builder.addCase(getDirection.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteDirection
    builder.addCase(deleteDirection.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDirection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.directions = payload;
    });
    builder.addCase(deleteDirection.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // creareEx
    builder.addCase(createEx.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEx.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.exs.push(payload);
    });
    builder.addCase(createEx.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getEx
    builder.addCase(getEx.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEx.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.exs = payload;
      // toast.success(`Сохранено!`);
    });
    builder.addCase(getEx.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // updateEx
    builder.addCase(updateEx.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEx.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.exs = payload;
    });
    builder.addCase(updateEx.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteEx
    builder.addCase(deleteEx.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteEx.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
      toast.success("Упражнение удалено");
    });
    builder.addCase(deleteEx.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createAim
    builder.addCase(createAim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAim.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims.push(payload);
      toast.success("Цель создана");
    });
    builder.addCase(createAim.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getAim
    builder.addCase(getAim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAim.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims = payload;
    });
    builder.addCase(getAim.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteAim
    builder.addCase(deleteAim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAim.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims = payload;
    });
    builder.addCase(deleteAim.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // addSong
    builder.addCase(createSong.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSong.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Сохранено!");
      state.songs.push(payload);
    });
    builder.addCase(createSong.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getSongs
    builder.addCase(getSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // toast.success("Сохранено!");
      state.songs = payload;
    });
    builder.addCase(getSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editSong
    builder.addCase(editSong.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editSong.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Изменения сохранены!");
      state.songs = payload;
    });
    builder.addCase(editSong.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteSong
    builder.addCase(deleteSong.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSong.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.songs = payload;
      toast.success("Удалено !");
    });
    builder.addCase(deleteSong.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createTheme
    builder.addCase(createTheme.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTheme.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.themes.push(payload);
      toast.success(`Тема ${payload.name} создана !`);
    });
    builder.addCase(createTheme.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getThemes
    builder.addCase(getThemes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getThemes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.themes = payload;
      // toast.success(`Тема ${payload.name} создана !`);
    });
    builder.addCase(getThemes.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editTheme
    builder.addCase(editTheme.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editTheme.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Изменения сохранены!");
      state.themes = payload;
    });
    builder.addCase(editTheme.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteTheme
    builder.addCase(deleteTheme.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteTheme.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Удалено !");
      state.themes = payload;
    });
    builder.addCase(deleteTheme.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createThemeSongs
    builder.addCase(createThemeSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createThemeSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Сохранено !");
      state.themeSongs.push(payload);
    });
    builder.addCase(createThemeSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getThemeSongs
    builder.addCase(getThemeSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getThemeSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // toast.success("Сохранено !");
      state.themeSongs = payload;
    });
    builder.addCase(getThemeSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteThemeSongs
    builder.addCase(deleteThemeSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteThemeSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Удалено !");
      state.themeSongs = payload;
    });
    builder.addCase(deleteThemeSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createBase
    builder.addCase(createBase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createBase.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Сохранено !");
      state.bases.push(payload);
    });
    builder.addCase(createBase.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getBases
    builder.addCase(getBases.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBases.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.bases = payload;
    });
    builder.addCase(getBases.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editBase
    builder.addCase(editBase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editBase.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.bases = payload;
      toast.success("Изменения сохранены !");
    });
    builder.addCase(editBase.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteBase
    builder.addCase(deleteBase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBase.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.bases = payload;
      toast.success("Удалено !");
    });
    builder.addCase(deleteBase.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createWeek
    builder.addCase(createWeek.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWeek.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks.push(payload);
      toast.success("Новая неделя добавлена!");
    });
    builder.addCase(createWeek.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getWeeks
    builder.addCase(getWeeks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWeeks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
      // toast.success("Создано!");
    });
    builder.addCase(getWeeks.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editWeek
    builder.addCase(editWeek.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editWeek.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
      toast.success("Изменения сохранены !");
    });
    builder.addCase(editWeek.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteWeek
    builder.addCase(deleteWeek.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWeek.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
      toast.success("Удалено !");
    });
    builder.addCase(deleteWeek.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createClass
    builder.addCase(createClass.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createClass.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.classes.push(payload);
      toast.success("Создано!");
    });
    builder.addCase(createClass.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getClasses
    builder.addCase(getClasses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClasses.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.classes = payload;
    });
    builder.addCase(getClasses.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteClass
    builder.addCase(deleteClass.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteClass.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.classes = payload;
      toast.success("Удалено !");
    });
    builder.addCase(deleteClass.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createWeekLevel
    builder.addCase(createWeekLevel.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWeekLevel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weekLevel = payload;
      toast.success("Изменения сохранены !");
    });
    builder.addCase(createWeekLevel.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // get WL
    builder.addCase(getWL.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWL.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weekLevel = payload;
    });
    builder.addCase(getWL.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // get allWl
    builder.addCase(getAllWL.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllWL.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weekLevelAll = payload;
    });
    builder.addCase(getAllWL.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // updateAccess
    builder.addCase(updateAccess.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAccess.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;
      toast.success("Глобальные права пользователя обновлены !");
    });
    builder.addCase(updateAccess.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // updateLevelsAccess
    builder.addCase(updateLevelsAccess.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLevelsAccess.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;
      toast.success("Права пользователя по уровняи обновлены !");
    });
    builder.addCase(updateLevelsAccess.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // deleteUser
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;
      toast.success("Пользователь удален!");
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // addWholeYear
    builder.addCase(addWholeYear.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addWholeYear.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
      toast.success("Год добавлен!");
    });
    builder.addCase(addWholeYear.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // add10Weeks
    builder.addCase(add10Weeks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(add10Weeks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
      toast.success("10 недель добавлено!");
    });
    builder.addCase(add10Weeks.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editAim
    builder.addCase(editAim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAim.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims = payload;
      toast.success("Сохранено !");
    });
    builder.addCase(editAim.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // editAimFromList
    builder.addCase(editAimFromList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAimFromList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims = payload;
      toast.success("Сохранено !");
    });
    builder.addCase(editAimFromList.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });
  },
});

export const {
  logOutAdmin,
  currentGroupHandler,
  currentExHandler,
  currentSongHandler,
  currentThemeHandler,
  currentBaseHandler,
  currentWeekHandler,
  currentIDHandler,
  currentClassHandler,
  cursorPositionHandler,
  LevelIdsHandler,
} = adminSlice.actions;
export default adminSlice.reducer;
