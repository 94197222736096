import React from "react";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";

const AimAdmin = ({ name, passList }) => {
  const addListHandler = (e) => {
    passList(name);
  };
  return (
    <Wrapper>
      <div>
        <FaPlus onClick={addListHandler} />
      </div>
      <p>{name}</p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  svg {
    margin: 0 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.6s;
    :hover {
      color: var(--blue-1);
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AimAdmin;
