import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import UserModalExtra from "../../components/UserCalendar/UserModalExtra";
import { useDispatch, useSelector } from "react-redux";

import {
  currentIDHandler,
  currentClassHandler,
  cursorPositionHandler,
} from "../../features/user/userSlise";
import Draggable from "react-draggable";

const ClassItem = ({ className, levelId, weekId, index }) => {
  const [ID, setID] = useState("");
  const { classes, currentID, user, isExtra, cursorPosition } = useSelector(
    (store) => store.user
  );

  useEffect(() => {
    const ID1 = weekId + index + levelId + className;
    setID(ID1);
  }, [ID]);

  const dispatch = useDispatch();

  const scrollRef = ID === currentID ? React.createRef() : null;

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [ID, currentID]);

  const handleMouseMove = (e) => {
    dispatch(cursorPositionHandler({ x: e.clientX, y: e.clientY }));
    dispatch(currentIDHandler(ID));
    if (ID === currentID) {
      dispatch(currentClassHandler(false));
      setID("");
      dispatch(currentIDHandler(null));
    } else {
      dispatch(currentClassHandler(true));
    }
  };

  const textContainerRef = useRef(null);
  const maxFontSize = 16;
  useEffect(() => {
    const resizeText = () => {
      const textContainer = textContainerRef.current;
      const content = textContainer.querySelector("p");
      if (!content) return;

      const containerWidth = textContainer.clientWidth;
      const containerHeight = textContainer.clientHeight;

      const scaleFactor = Math.min(
        containerWidth / content.scrollWidth,
        containerHeight / content.scrollHeight
      );

      let newFontSize = Math.floor(
        parseFloat(getComputedStyle(content).fontSize) * scaleFactor
      );

      if (maxFontSize && newFontSize > maxFontSize) {
        newFontSize = maxFontSize;
      }

      content.style.fontSize = newFontSize + "px";
    };

    window.addEventListener("resize", resizeText);
    resizeText();

    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, [maxFontSize]);

  const thisLevel = _.filter(classes, { groupId: levelId });
  const thisLevel2 = _.find(thisLevel, { name: className });

  // ________________________________________________________

  const [isDragging, setIsDragging] = useState(false);

  const handleStartDrag = () => {
    setIsDragging(true);
  };

  const handleStopDrag = () => {
    setIsDragging(false);
  };

  const handleClickOutside = (event) => {
    if (
      textContainerRef.current &&
      !textContainerRef.current.contains(event.target) &&
      !event.target.closest(".extra")
    ) {
      dispatch(currentClassHandler(false));
      // setID("");
      // dispatch(currentIDHandler(null));
    }
  };

  useEffect(() => {
    if (isExtra) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExtra]);
  // ________________________________________________________

  return (
    <Wrapper
      className={ID === currentID ? "active" : ""}
      ref={textContainerRef}
      x={cursorPosition?.x}
      y={cursorPosition?.y}
    >
      <div className="resize" ref={scrollRef} onClick={handleMouseMove}>
        {className === "нет" ? <p></p> : <p>{className}</p>}
      </div>
      {isExtra && user.AExs === true && ID === currentID && (
        <Draggable
          handle=".header-draggable"
          onStart={handleStartDrag}
          onStop={handleStopDrag}
        >
          <div className={isDragging ? "extra extra-dragging" : "extra"}>
            {thisLevel2?.list.length === undefined && (
              <div className="empty">
                <p>Здесь ни чего нет</p>
              </div>
            )}
            <div className="header-draggable"></div>
            {thisLevel2?.list.map((l, index) => (
              <UserModalExtra key={index} l={l} />
            ))}
          </div>
        </Draggable>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 50px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  width: 200px;
  cursor: pointer;
  transition: 0.6s;

  &.active {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    background: var(--blue-3);
  }
  :hover {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    background: var(--blue-3);
  }
  :active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  .resize {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .extra {
    position: absolute;
    top: ${(props) => `${props.y + 30}px`};
    left: ${(props) => `${props.x - 150}px`};
    width: 250px;
    height: max-content;
    background: white;
    z-index: 1;
    border: 1px solid var(--blue-0);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .empty {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-draggable {
    min-height: 60px;
    background: var(--blue-3);
    cursor: grab;
  }
  .extra-dragging {
    opacity: 0.7;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ClassItem;
