import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import UserEx from "../components/userEx/UserEx";
import Input2 from "../components-special/Input2";
import { getEx } from "../features/user/userSlise";
import _ from "lodash";

const initialState = {
  search: "",
};

const AllExUser = () => {
  const { exs, user } = useSelector((store) => store.user);
  const [values, setValues] = useState(initialState);

  const sortedExs = _.sortBy(exs, ["name"]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.AExs === true) {
      dispatch(getEx());
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Все упражнения</h4>
          </div>
          <div className="search">
            <Input2
              type="text"
              name="search"
              value={values.search.toLowerCase()}
              onChange={changeHandler}
            />
          </div>

          {sortedExs.map((e) => (
            <UserEx
              key={e._id}
              name={e.name}
              id={e._id}
              description={e.description}
              image={e.image}
              search={values.search}
            />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AllExUser;
