import React, { useState } from "react";
import styled from "styled-components";
import InputPass from "../components-special/InputPass";
import Input from "../components-special/Input";
import Button from "../components-special/Button";
import { changePass, changeEmail } from "../features/user/userSlise";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

const ChangePassword = () => {
  const { user, isLoading } = useSelector((store) => store.user);
  const initialState = {
    pass: "",
    pass1: "",
    pass2: "",
    email: user.email,
  };

  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { pass, pass1, pass2 } = values;
    if (!pass || !pass1 || !pass2) {
      toast.error("Введите все значения");
      return;
    }
    if (pass1 !== pass2) {
      toast.error("Введенные пароли не совпадают !");
      return;
    }
    dispatch(
      changePass({
        pass: pass,
        pass1: pass1,
        pass2: pass2,
        id: user._id,
      })
    );
    setValues(initialState);
  };

  const emailHandler = () => {
    if (!values.email) {
      toast.error("Укажите Email");
      return;
    }
    dispatch(changeEmail({ id: user._id, email: values.email }));
  };

  return (
    <Wrapper>
      <h4>Изменить пароль</h4>
      <form onSubmit={onSubmit}>
        <div className="password">
          <label>
            <span>*</span>Текущий пароль
          </label>
          <div>
            <InputPass
              name="pass"
              type="password"
              value={values.pass}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="password">
          <label>
            <span>*</span>Новый пароль
          </label>
          <div>
            <InputPass
              name="pass1"
              type="password"
              value={values.pass1}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="password">
          <label>
            <span>*</span>Повторите пароль
          </label>
          <div>
            <InputPass
              name="pass2"
              type="password"
              value={values.pass2}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="actions">
          <Button text="Сохранить" type="submit" />
        </div>

        <div className="password">
          <label>Email</label>
          <div className="email">
            <Input
              name="email"
              type="email"
              value={values.email}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="actions">
          <Button text="Сохранить" type="button" onClick={emailHandler} />
        </div>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .password {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  input {
    width: 200px;
  }
  .email {
    width: 100%;
    input {
      width: 250px;
    }
  }
  label {
    margin-left: 1rem;
    margin-bottom: 0;
  }
  .actions {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  span {
    color: var(--red-1-);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .password {
      align-items: flex-start;
    }
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;
export default ChangePassword;
