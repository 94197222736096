import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "../../components-special/Select2";
import LevelWeek from "./LevelWeek";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import {
  editWeek,
  deleteWeek,
  getWL,
  getAllWL,
} from "../../features/adminSlice";
import _ from "lodash";
import ConfirmModal from "../../components-special/ConfirmModal";

const ModalWeek = ({ passState, weekId, week }) => {
  const { currentWeek, weeks, themes, groups } = useSelector(
    (store) => store.admin
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWL({ weekId: currentWeek }));
  }, [dispatch, currentWeek]);

  const thisWeek = weeks.find((w) => w._id === currentWeek);

  const [themeWeek, setThemeWeek] = useState(thisWeek.theme);

  const themeHandler = (theme) => {
    setThemeWeek(theme);
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteWeek({ weekId: currentWeek }));
    }
  };

  const deleteWeekHandler = () => {
    // dispatch(deleteWeek({ weekId: currentWeek }));
    setIsConfirmModal(true);
  };

  const themeSaveHandler = () => {
    dispatch(editWeek({ weekId: currentWeek, theme: themeWeek }));
  };

  const modalHandler = () => {
    passState(false);
    dispatch(getAllWL());
  };

  const groups2 = _.sortBy(groups, "createdAt");
  const sortedGroup = _.sortBy(groups, [(item) => item.name[1]]);

  return (
    <>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Wrapper>
        <div className="modal">
          <div className="close" onClick={modalHandler}>
            <AiOutlineClose />
          </div>
          <div className="header">
            <h4>{week}</h4>
          </div>

          <div className="content cont">
            <div className="theme cont">
              <p>Тема недели</p>
              <Select2
                passState={themeHandler}
                data={themes}
                def={thisWeek.theme}
              />
              <Button
                text="Сохранить"
                type="button"
                onClick={themeSaveHandler}
              />
            </div>
            <div className="levels">
              {sortedGroup.map((g) => (
                <LevelWeek
                  key={g._id}
                  name={g.name}
                  groupId={g._id}
                  weekId={weekId}
                />
              ))}
            </div>
          </div>
          <div className="actions">
            <Delete text="Удалить" type="button" onClick={deleteWeekHandler} />
          </div>
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 20, 20, 0.5);
  transition: 1s;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  z-index: 998;
  opacity: 1;
  .modal {
    width: 90vw;
    height: 90vh;
    background-color: white;
    border-radius: 15px;
    overflow: auto;
  }
  .close {
    display: flex;
    justify-content: end;
    margin: 1rem;
    margin-bottom: 0;
    cursor: pointer;
    svg {
      font-size: 1.8rem;
      transition: 0.7s;
    }
    :hover {
      svg {
        color: var(--blue-0);
      }
    }
    :active {
      svg {
        color: var(--blue-1);
      }
    }
  }
  .content {
    padding: 1rem;
  }
  .cont {
    margin-bottom: 1rem;
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    div {
      margin: 0 1rem;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .modal {
      width: 50vw;
      height: 80vh;
      background-color: white;
      border-radius: 15px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ModalWeek;
