import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Loading2 from "../Loading2";
import _ from "lodash";
import { LevelIdsHandler } from "../../features/user/userSlise";

const Levels = () => {
  const { groups, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const groups2 = _.sortBy(groups, "createdAt");
  const sortedGroup = _.sortBy(groups, [(item) => item.name[1]]);
  // console.log(sortedGroup);
  const levelIds = sortedGroup.map((item) => item._id);

  useEffect(() => {
    dispatch(LevelIdsHandler(levelIds));
  }, [groups]);

  return (
    <>
      {isLoading && <Loading2 />}
      {!isLoading && (
        <Wrapper>
          <div className="head">
            <p>Уровень группы</p>
          </div>
          <div className="theme">
            <p>Тема недели</p>
          </div>
          {sortedGroup?.map((g) => (
            <div className="level" key={g._id}>
              <p>{g.name}</p>
            </div>
          ))}
        </Wrapper>
      )}
    </>
  );
};
const Wrapper = styled.div`
  min-width: 150px;
  background: var(--blue-3);
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);

  .head {
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    p {
      color: var(--blue-0);
      font-size: 1.1rem;
    }
  }
  .theme {
    padding: 1rem 0;
    /* border-bottom: 1px solid gray; */
    height: 50px;
    display: flex;
    justify-content: center;
  }
  .level {
    padding: 1rem 0;
    /* border-bottom: 1px solid gray; */
    height: 50px;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start;
    padding-left: 5px;
    align-items: center;
  }

  @media (min-width: 576px) {
    min-width: 200px;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Levels;
