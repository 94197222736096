import { nanoid } from "nanoid";
export const years = [
  { id: nanoid(), name: 2023 },
  { id: nanoid(), name: 2024 },
  { id: nanoid(), name: 2025 },
  { id: nanoid(), name: 2026 },
  { id: nanoid(), name: 2027 },
  { id: nanoid(), name: 2028 },
  { id: nanoid(), name: 2029 },
  { id: nanoid(), name: 2030 },
  { id: nanoid(), name: 2031 },
  { id: nanoid(), name: 2032 },
  { id: nanoid(), name: 2033 },
  { id: nanoid(), name: 2034 },
  { id: nanoid(), name: 2035 },
  { id: nanoid(), name: 2036 },
  { id: nanoid(), name: 2037 },
  { id: nanoid(), name: 2038 },
  { id: nanoid(), name: 2039 },
  { id: nanoid(), name: 2040 },
  { id: nanoid(), name: 2041 },
  { id: nanoid(), name: 2042 },
  { id: nanoid(), name: 2043 },
];
