import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import Video from "../components/userGroups/Video";
import {
  getVideo,
  getComments,
  getThemeSongs,
  currentThemeHandler,
} from "../features/user/userSlise";
import CommentsSong from "../components/Comments/CommentsSong";

import _ from "lodash";

const ExPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentSong, video, songs, themeSongs, themes, user } = useSelector(
    (store) => store.user
  );

  const r1 = _.filter(themeSongs, function (o) {
    return o.songs.some((song) => song.id === currentSong);
  });

  const r2 = _.uniqBy(r1, "themeId");

  const r3 = _.chain(themeSongs)
    .filter((themesong) =>
      themesong.songs.some((song) => song.id === currentSong)
    )
    .uniqBy("themeId")
    .value();

  const thisSong = songs.find((song) => song._id === currentSong);
  let thisVideo;
  thisVideo = _.filter(video, { groupId: currentSong });

  useEffect(() => {
    if (!currentSong) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (currentSong) {
      dispatch(getVideo({ groupId: currentSong }));
    }
  }, [currentSong]);

  useEffect(() => {
    if (currentSong) {
      dispatch(getComments({ id: currentSong }));
    }
  }, [currentSong]);

  useEffect(() => {
    dispatch(getThemeSongs());
  }, [currentSong]);

  const setThemeHandler = (id) => {
    dispatch(currentThemeHandler(id));
    navigate("/personal-area/theme/");
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.ASongs && (
        <Wrapper>
          {currentSong && (
            <main>
              <div className="header">
                <h4>{thisSong?.name}</h4>
              </div>
              <div className="content">
                <div className="about">
                  <p className="info">Описание</p>
                  <p>{thisSong?.description}</p>
                  <div className="video">
                    {thisVideo.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                      />
                    ))}
                  </div>
                </div>
                <div className="comments">
                  {user.AThemes === true && (
                    <div className="groups">
                      <p>Темы</p>
                      {r3.map((r) => {
                        const { themeId, _id, name } = r;
                        const currentTheme = themes.find(
                          (theme) => theme._id === themeId
                        );
                        return (
                          <div
                            key={_id}
                            className="group"
                            onClick={() => setThemeHandler(themeId)}
                          >
                            {currentTheme.name}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div className="logo">{/* <CommentsSong /> */}</div>
                </div>
              </div>
            </main>
          )}
        </Wrapper>
      )}
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .label {
    font-size: 1.2rem;
    color: var(--clr-grey-5);
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .about {
    width: 100%;
    padding: 1rem 0;

    p {
      white-space: pre-wrap;
      user-select: none;
    }
  }
  .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .comments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .logo {
    margin: 1rem;
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .name {
    cursor: pointer;
    margin-left: 1rem;
  }
  .groups {
    margin-left: 1rem;
  }
  .group {
    margin-left: 1rem;
    color: var(--blue-1);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about {
      width: 600px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1200px) {
    .about {
      width: 800px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .about {
      width: 900px;
    }
    .comments {
      width: 300px;
    }
  }
`;
export default ExPage;
