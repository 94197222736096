import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginUserThunk,
  getGroupsThunk,
  getVideoThunk,
  createCommentThunk,
  getCommentThunk,
  getExThunk,
  getAimThunk,
  getSongsThunk,
  getThemeSongsThunk,
  getBasesThunk,
  getWeeksThunk,
  getWLAllThunk,
  getClassesThunk,
  changePassThunk,
  changeEmailThunk,
} from "./thunk";
import toast from "react-hot-toast";
import {
  addTokenToLocalStorage,
  addUserToLocalStorage,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  removeTokenFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";

const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  token: getTokenFromLocalStorage(),
  isSidebarOpen: false,
  pageId: "",
  groups: [],
  currentGroup: "",
  currentEx: "",
  currentSong: "",
  currentTheme: "",
  currentBase: "",
  themes: [],
  themeSongs: [],
  video: [],
  comments: [],
  exs: [],
  aims: [],
  songs: [],
  bases: [],
  weeks: [],
  weekLevelAll: [],
  currentID: null,
  classes: [],
  isExtra: false,
  cursorPosition: {},
  levelIds: [],
};

const showWarningToast = (payload) => {
  toast(payload, {
    icon: "⚠️",
    style: {
      borderRadius: "10px",
      background: "white",
      color: "var(--blue-0)",
    },
  });
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    return loginUserThunk(`/auth/login/`, user, thunkAPI);
  }
);

export const getGroups = createAsyncThunk(
  "user/getGroups",
  async (user, thunkAPI) => {
    return getGroupsThunk(`/user/get_groups/`, user, thunkAPI);
  }
);

export const getVideo = createAsyncThunk(
  "user/getVideo",
  async (info, thunkAPI) => {
    return getVideoThunk(`/user/get_video/${info.groupId}`, info, thunkAPI);
  }
);

export const createComment = createAsyncThunk(
  "user/createComment",
  async (info, thunkAPI) => {
    return createCommentThunk(`/user/create_comment/`, info, thunkAPI);
  }
);

export const getComments = createAsyncThunk(
  "user/getComments",
  async (info, thunkAPI) => {
    return getCommentThunk(`/user/get_comments/${info.id}`, info, thunkAPI);
  }
);

export const getEx = createAsyncThunk("user/getEx", async (info, thunkAPI) => {
  return getExThunk(`/user/get_ex/`, info, thunkAPI);
});

export const getAim = createAsyncThunk(
  "user/getAim",
  async (info, thunkAPI) => {
    return getAimThunk(`/user/get_aim/`, info, thunkAPI);
  }
);

export const getSongs = createAsyncThunk(
  "user/getSongs",
  async (info, thunkAPI) => {
    return getSongsThunk(`/user/get_songs/`, info, thunkAPI);
  }
);

export const getThemeSongs = createAsyncThunk(
  "admin/get_themeSongs",
  async (info, thunkAPI) => {
    return getThemeSongsThunk(`/user/get_themesongs`, info, thunkAPI);
  }
);

export const getBases = createAsyncThunk(
  "admin/get_bases",
  async (info, thunkAPI) => {
    return getBasesThunk(`/user/get_bases`, info, thunkAPI);
  }
);

export const getWeeks = createAsyncThunk(
  "admin/get_weeks",
  async (info, thunkAPI) => {
    return getWeeksThunk(`/user/get_weeks`, info, thunkAPI);
  }
);

export const getAllWL = createAsyncThunk(
  "user/getAllWeekLevel",
  async (info, thunkAPI) => {
    return getWLAllThunk(`/user/getAll_weeklevel`, info, thunkAPI);
  }
);

// error
export const getClasses = createAsyncThunk(
  "user/get_classes",
  async (info, thunkAPI) => {
    return getClassesThunk(`/admin/get_classes`, info, thunkAPI);
  }
);

export const changePass = createAsyncThunk(
  "user/changePass",
  async (info, thunkAPI) => {
    return changePassThunk(`/user/changePass`, info, thunkAPI);
  }
);

export const changeEmail = createAsyncThunk(
  "user/changeEmails",
  async (info, thunkAPI) => {
    return changeEmailThunk(`/user/changeEmail`, info, thunkAPI);
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOutUser: (state) => {
      state.user = null;
      state.token = "";
      removeUserFromLocalStorage();
      removeTokenFromLocalStorage();
      state.weeks = [];
      state.groups = [];
      state.exs = [];
      state.aims = [];
      state.songs = [];
      state.themes = [];
      state.themeSongs = [];
      state.classes = [];
      state.bases = [];
      state.weekLevelAll = [];
      state.currentID = "";
      state.currentEx = "";
      state.currentGroup = "";
      state.currentSong = "";
      state.currentBase = "";
      state.currentTheme = "";
      state.cursorPosition = {};
      state.isExtra = "false";
      state.levelIds = [];
      state.isSidebarOpen = false;
    },
    currentIDHandler: (state, { payload }) => {
      state.currentID = payload;
    },
    sidebarCloseHandler: (state) => {
      state.isSidebarOpen = false;
    },
    sidebarOpenHandler: (state) => {
      state.isSidebarOpen = true;
    },
    pageIdHandler: (state, { payload }) => {
      state.pageId = payload;
    },
    currentGroupHandler: (state, { payload }) => {
      state.currentGroup = payload;
    },
    currentExHandler: (state, { payload }) => {
      state.currentEx = payload;
    },
    currentSongHandler: (state, { payload }) => {
      state.currentSong = payload;
    },
    currentThemeHandler: (state, { payload }) => {
      state.currentTheme = payload;
    },
    currentBaseHandler: (state, { payload }) => {
      state.currentBase = payload;
    },
    currentClassHandler: (state, { payload }) => {
      state.isExtra = payload;
    },
    cursorPositionHandler: (state, { payload }) => {
      state.cursorPosition = payload;
    },
    LevelIdsHandler: (state, { payload }) => {
      state.levelIds = payload;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const { user, token } = payload;
      state.isLoading = false;
      state.user = user;
      state.token = token;
      addUserToLocalStorage(user);
      addTokenToLocalStorage(token);
      toast.success(`Добро пожаловать  ${user.name} !`);
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      // toast.error(payload);
      showWarningToast(payload);
    });

    // getGroups
    builder.addCase(getGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
    });
    builder.addCase(getGroups.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getVideo
    builder.addCase(getVideo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVideo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.video = payload;
    });
    builder.addCase(getVideo.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // createComment
    builder.addCase(createComment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createComment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comments.push(payload);
    });
    builder.addCase(createComment.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });
    // createComment
    builder.addCase(getComments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getComments.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comments = payload;
    });
    builder.addCase(getComments.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getEx
    builder.addCase(getEx.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEx.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.exs = payload;
    });
    builder.addCase(getEx.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getAim
    builder.addCase(getAim.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAim.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.aims = payload;
    });
    builder.addCase(getAim.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getSongs
    builder.addCase(getSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.songs = payload;
    });
    builder.addCase(getSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getThemeSongs
    builder.addCase(getThemeSongs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getThemeSongs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.themes = payload.themes;
      state.themeSongs = payload.themeSongs;
    });
    builder.addCase(getThemeSongs.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getBases
    builder.addCase(getBases.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBases.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.bases = payload;
    });
    builder.addCase(getBases.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getWeeks
    builder.addCase(getWeeks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWeeks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weeks = payload;
    });
    builder.addCase(getWeeks.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // get allWl
    builder.addCase(getAllWL.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllWL.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.weekLevelAll = payload;
    });
    builder.addCase(getAllWL.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // getClasses
    builder.addCase(getClasses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClasses.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.classes = payload;
    });
    builder.addCase(getClasses.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // changePass
    builder.addCase(changePass.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePass.fulfilled, (state, { payload }) => {
      const { user, token } = payload;
      state.isLoading = false;
      state.user = user;
      state.token = token;
      addUserToLocalStorage(user);
      addTokenToLocalStorage(token);
      toast.success("Изменения сохранены !");
    });
    builder.addCase(changePass.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });

    // changeEmail
    builder.addCase(changeEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeEmail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { user } = payload;
      state.user = user;
      addUserToLocalStorage(user);
      toast.success("Изменения сохранены !");
    });
    builder.addCase(changeEmail.rejected, (state, { payload }) => {
      state.isLoading = false;
      showWarningToast(payload);
    });
  },
});

export const {
  logOutUser,
  sidebarCloseHandler,
  sidebarOpenHandler,
  pageIdHandler,
  currentGroupHandler,
  currentExHandler,
  currentSongHandler,
  currentThemeHandler,
  currentBaseHandler,
  currentIDHandler,
  currentClassHandler,
  cursorPositionHandler,
  LevelIdsHandler,
} = userSlice.actions;
export default userSlice.reducer;
