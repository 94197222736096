import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import toast from "react-hot-toast";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import ChangePassword from "../components/ChangePassword";

const initialState = {
  // pass: "",
  // pass1: "",
  // pass2: "",
};

const UserSettingsPage = () => {
  const { user } = useSelector((store) => store.user);

  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch();

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>
              {user.name} {user.secondName}
            </h4>
          </div>
          <div className="user-info">
            <div className="info">
              <div className="info2">
                <p className="key">Логин</p>
                <p className="value">{user.login}</p>
              </div>
              <div className="info2">
                <p className="key">Почта</p>
                <p className="value">{user.email}</p>
              </div>
            </div>
            <div className="password">
              <ChangePassword />
            </div>
          </div>
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .user-info {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: center;
  }
  .info {
    display: flex;
    flex-direction: column;
    .info2 {
      display: flex;
      p {
        margin: 1rem;
      }
    }
  }
  .key {
    font-size: 1.2rem;
  }
  .value {
    color: var(--blue-0);
    font-size: 1.2rem;
  }
  .password {
    margin: 1rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default UserSettingsPage;
