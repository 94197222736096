import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { currentThemeHandler } from "../../features/user/userSlise";
import UserClassItem from "../../components/UserCalendar/UserClassItem";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const Week = ({ week, year, currentWeekNumber, weekId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentYear = moment().year();

  const { weeks, weekLevelAll, themes, levelIds } = useSelector(
    (store) => store.user
  );

  const thisWeek = weeks.find((w) => w._id === weekId);

  const thisWeekLevels = _.filter(weekLevelAll, { weekId: weekId });

  const filteredAndSortedArray = _.chain(thisWeekLevels)
    // .filter((item) => _.includes(levelIds, item.levelId))
    .sortBy((item) => _.indexOf(levelIds, item.levelId)) // Сортировка
    .value();

  // console.log(thisWeekLevels);

  const getFirstAndLastDayOfWeek = (year, weekNumber) => {
    const firstDayOfWeek = moment().year(year).week(weekNumber).day(1);
    const lastDayOfWeek = moment().year(year).week(weekNumber).day(7);

    return {
      firstDay: firstDayOfWeek.format("DD-MM-YY"),
      lastDay: lastDayOfWeek.format("DD-MM-YY"),
    };
  };

  const { firstDay, lastDay } = getFirstAndLastDayOfWeek(year, week);

  const navigateHandler = () => {
    const thisTheme = themes.find((t) => t.name === thisWeek.theme);
    dispatch(currentThemeHandler(thisTheme._id));
    navigate("/personal-area/theme/");
  };

  return (
    <>
      <Wrapper
        week={week}
        currentWeekNumber={currentWeekNumber}
        year={year}
        currentYear={currentYear}
      >
        <div className="head">
          <p className="header-text">{week}</p>
          <p className="small-text">
            {firstDay} - {lastDay}
          </p>
        </div>
        {thisWeek?.theme && (
          <div className="theme" onClick={navigateHandler}>
            <p>{thisWeek.theme}</p>
          </div>
        )}
        {filteredAndSortedArray.map((twl, index) => (
          <UserClassItem
            key={twl._id}
            levelId={twl.levelId}
            className={twl.className}
            weekId={twl._id}
            index={index}
          />
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-width: 200px;
  .head {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--blue-3);
    background: ${(props) =>
      props.week === props.currentWeekNumber &&
      props.year === props.currentYear &&
      "var(--blue-2)"};
    height: 75px;
    cursor: pointer;
    transition: 0.6s;
    :hover {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
      scale: 1.1;
    }
    :active {
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
    p {
      color: var(--blue-0);
      color: ${(props) =>
        props.week === props.currentWeekNumber &&
        props.year === props.currentYear &&
        "white"};
    }
    .header-text {
      font-size: 1.1rem;
    }
    .small-text {
      font-size: 0.8rem;
    }
  }
  .theme {
    padding: 1rem 0;
    height: 50px;
    display: flex;
    justify-content: center;
    transition: 0.6s;
    cursor: pointer;
    :hover {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
      background: var(--blue-3);
    }
    :active {
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .level {
    padding: 1rem 0;
    height: 50px;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Week;
