import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Input = ({ type, placeholder, value, name, onChange }) => {
  return (
    <Wrapper>
      <motion.input
        whileFocus={{
          scale: 1.1,
        }}
        transition={{
          type: "spring",
          stiffness: 200,
        }}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  /* margin: auto; */
  width: 100%;

  input {
    box-sizing: border-box;
    padding: 1rem;
    border: none;
    background: var(--gray-1);
    height: 35px;
    width: calc(100%);
    font-size: 100%;
    border-radius: 5px;
    color: var(--blue-1);
    border: 1px solid var(--blue-1);

    ::placeholder {
      color: var(--blue-05);
    }
    :focus-visible {
      outline: none;
      border: 3px solid var(--blue-1);
    }

    :hover {
      ::placeholder {
        transition: 0.5s;
      }
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;
export default Input;
