import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select3 from "../../components-special/Select3";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Button from "../../components-special/Button";
import { createWeekLevel } from "../../features/adminSlice";

const LevelWeek = ({ name, groupId }) => {
  const dispatch = useDispatch();
  const { classes, currentWeek, weekLevel } = useSelector(
    (store) => store.admin
  );

  const thisWL = weekLevel.find((w) => w.levelId === groupId);

  const [currentClassName, setCurrentClassName] = useState();
  const currentClasses = _.filter(classes, { groupId: groupId });

  const currentClassHandler = (data) => {
    setCurrentClassName(data);
  };

  const saveHandler = () => {
    dispatch(
      createWeekLevel({
        weekId: currentWeek,
        levelId: groupId,
        className: currentClassName,
      })
    );
  };

  return (
    <Wrapper>
      <div className="name">
        <p className="level">{name}</p>
        <p className="level2">{thisWL?.className}</p>
      </div>

      <div className="content">
        <Select3
          passState={currentClassHandler}
          data={currentClasses}
          def={currentClassName}
        />
        <Button text="Сохранить" type="button" onClick={saveHandler} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 1rem;
  .name {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: var(--transition2);
    .label {
      margin: 0.5rem 1rem;
    }
    .index {
      margin: 0.5rem 1rem;
    }
    :hover {
      background-color: var(--blue-3);
    }
  }
  .name-active {
    background-color: var(--blue-3);
  }
  .level {
    color: var(--blue-0);
    margin-right: 1rem;
  }
  .level2 {
    font-style: italic;
    font-size: 0.8rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default LevelWeek;
