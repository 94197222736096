import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentExHandler } from "../../features/adminSlice";

const ModalExtra = ({ l }) => {
  const { exs } = useSelector((store) => store.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const thisEx = exs?.find((ex) => ex.name === l);

  const navigateHandler = () => {
    dispatch(currentExHandler(thisEx?._id));
    navigate("/a-panel/edit_ex/");
  };

  return (
    <Wrapper>
      <p className="ex" onClick={navigateHandler}>
        {l}
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.5rem;
  :hover {
    background: var(--blue-3);
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default ModalExtra;
