import React from "react";
import styled from "styled-components";
import { FcVideoFile, FcFile } from "react-icons/fc";
import FileDownload from "js-file-download";
import Axios from "axios";

const { REACT_APP_SPACE } = process.env;

const Video = ({ name, label, groupId, video, mimetype }) => {
  console.log(video);
  const downloadHandler = (e) => {
    e.preventDefault();
    Axios({
      url: `${REACT_APP_SPACE}/${video}`,
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      FileDownload(res.data, video);
    });
  };
  console.log(mimetype);

  return (
    <Wrapper>
      {mimetype === "application/pdf" && <FcFile onClick={downloadHandler} />}
      {mimetype !== "application/pdf" && (
        <video src={`${REACT_APP_SPACE}/${video}`} controls muted>
          Ваш браузер не поддерживает видео
        </video>
      )}
      <p className="name">{name}</p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  margin: 1rem;
  /* justify-content: space-between; */
  video {
    width: 200px;
    height: 150px;
  }
  .info {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
  }
  svg {
    font-size: 3rem;
    transition: 0.5s;
    cursor: pointer;
    transition: 0.5s;
    :hover {
      scale: 1.1;
    }
  }
  .name {
    color: var(--blue-0);
    word-break: break-all;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Video;
