import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import toast from "react-hot-toast";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import Input2 from "../../components-special/Input2";
import Button from "../../components-special/Button";
import AimAdmin from "../../components/adminAim/AimAdmin";
import AimList from "../../components/adminAim/AimList";
import { getEx, createAim, getAim } from "../../features/adminSlice";
import { FaMinus } from "react-icons/fa";
import _ from "lodash";

const initialState = {
  name: "",
};

const AimPage = () => {
  const { exs, aims } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = useState(initialState);
  const [list, setList] = useState([]);

  const addListHandler = (data) => {
    setList([...list, data]);
  };

  const removeListHandler = (l) => {
    setList(list.filter((n) => n !== l));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!values.name) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(createAim({ name: values.name, list }));
      setList([]);
      setValues(initialState);
    }
  };

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getAim());
  // }, [dispatch]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Цели</h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="in">
              <p className="info">Название цели</p>

              <Input2
                placeholder="новая цель"
                type="text"
                name="name"
                value={values.name}
                onChange={changeHandler}
              />
            </div>
            <div className="content">
              <div className="in list">
                <p className="info">Список упражнений</p>
                {exs?.map((ex) => (
                  <AimAdmin
                    key={ex._id}
                    name={ex.name}
                    passList={addListHandler}
                  />
                ))}
              </div>
              <div className="in list">
                <p className="info">Добавленные упражнения</p>
                {_.uniq(list).map((l, index) => {
                  return (
                    <div className="added-item" key={index}>
                      <FaMinus onClick={() => removeListHandler(l)} />
                      <p>{l}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="actions">
              <Button text="Создать цель" />
            </div>
          </form>
          <div className="all-aim">
            <p className="info">Список созданных целей</p>

            {aims?.map((aim) => (
              <AimList
                name={aim.name}
                list={aim.list}
                key={aim._id}
                id={aim._id}
              />
            ))}
          </div>
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .in {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .actions {
    margin: 1rem;
    display: flex;
    justify-content: center;
  }
  .added-item {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--purple-2);
      }
    }
  }
  .all-aim {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .list {
    display: flex;
    align-items: baseline;
  }
  @media (min-width: 576px) {
    input {
      width: 500px;
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      flex-direction: row;
      justify-content: space-between;
      width: 80vw;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default AimPage;
