import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Theme from "../../components/adminTheme/Theme";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import Input2 from "../../components-special/Input2";

import _ from "lodash";

const initialState = {
  search: "",
};
const AllThemes = () => {
  const { themes } = useSelector((store) => store.admin);

  const sortedThemes = _.sortBy(themes, ["name"]);

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Темы</h4>
          </div>
          <div className="search">
            <Input2
              type="text"
              name="search"
              value={values.search.toLowerCase()}
              onChange={changeHandler}
            />
          </div>
          {sortedThemes?.map((theme) => (
            <Theme
              key={theme._id}
              id={theme._id}
              name={theme.name}
              search={values.search}
            />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default AllThemes;
