import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import {
  getVideo,
  getGroups,
  getComments,
  getAim,
  getEx,
} from "../features/user/userSlise";
import Video from "../components/userGroups/Video";
import _ from "lodash";
// import Comments from "../components/Comments/Comments";
import AimUser from "../components/userGroups/AimUser";

const { REACT_APP_SPACE } = process.env;

const GroupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentGroup, groups, video, aims, user } = useSelector(
    (store) => store.user
  );

  let aims2;
  let aims3;

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  useEffect(() => {
    if (user.AExs === true) {
      dispatch(getEx());
    }
  }, []);

  const thisGroup = groups.find((g) => g._id === currentGroup);
  // const aims = _.split([thisGroup.aims], ",");

  aims2 = _.filter(aims, { groupId: currentGroup });
  aims3 = _.sortBy(aims2, (item) => item.name.substring(0, 2));

  let thisVideo;
  let thisVideo1;
  let thisVideo2;
  thisVideo = _.filter(video, { groupId: currentGroup });
  thisVideo1 = _.filter(thisVideo, { label: "для описания" });
  thisVideo2 = _.filter(thisVideo, { label: "для требования к инструктору" });

  useEffect(() => {
    if (!thisGroup) {
      navigate("/");
    }
  }, [thisGroup]);

  useEffect(() => {
    if (currentGroup) {
      dispatch(getVideo({ groupId: currentGroup }));
    }
  }, [currentGroup]);

  useEffect(() => {
    if (currentGroup) {
      dispatch(getComments({ id: currentGroup }));
    }
  }, [currentGroup]);

  useEffect(() => {
    dispatch(getAim());
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.ALevels === true && (
        <Wrapper>
          {currentGroup && thisGroup && (
            <main>
              <div className="header">
                <h4>{thisGroup.name}</h4>
                <p className="label">{thisGroup.label}</p>
              </div>
              <div className="content">
                <div className="about">
                  <div className="in">
                    <p className="info">Описание</p>
                    <p>{thisGroup.description}</p>
                    <div className="video">
                      {thisVideo1.map((v) => (
                        <Video
                          key={v._id}
                          name={v.name}
                          label={v.label}
                          groupId={v.groupId}
                          video={v.video}
                          mimetype={v.mimetype}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="in">
                    <p className="info">Требования к инструктору</p>
                    <p>{thisGroup.instructor}</p>
                    <div className="video">
                      {thisVideo2.map((v) => (
                        <Video
                          key={v._id}
                          name={v.name}
                          label={v.label}
                          groupId={v.groupId}
                          video={v.video}
                          mimetype={v.mimetype}
                        />
                      ))}
                    </div>
                    {user.AExs === true && (
                      <div className="in">
                        <p className="info">Цели учеников</p>
                        {aims3.map((aim) => (
                          <AimUser
                            key={aim._id}
                            name={aim.name}
                            list={aim.list}
                            id={aim._id}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="comments">
                  <div className="logo">
                    <img
                      src={`${REACT_APP_SPACE}/${thisGroup.image}`}
                      alt="img"
                    />
                    {/* <Comments /> */}
                  </div>
                </div>
              </div>
            </main>
          )}
        </Wrapper>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .label {
    font-size: 1.2rem;
    color: var(--clr-grey-5);
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .about {
    width: 100%;
    padding: 1rem 0;

    p {
      white-space: pre-wrap;
      user-select: none;
    }
  }
  .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .comments {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .logo {
    margin: 1rem;
    img {
      width: 100%;
      max-width: 250px;
    }
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about {
      width: 600px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1200px) {
    .about {
      width: 800px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .about {
      width: 900px;
    }
    .comments {
      width: 300px;
    }
  }
`;

export default GroupPage;
