import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import Loading2 from "../../components/Loading2";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import _ from "lodash";
import {
  editTheme,
  deleteTheme,
  getSongs,
  createThemeSongs,
  getThemeSongs,
  deleteThemeSongs,
  currentSongHandler,
} from "../../features/adminSlice";
import Select from "../../components-special/Select";
import { FaPlus, FaMinus } from "react-icons/fa";
import ConfirmModal from "../../components-special/ConfirmModal";
import ConfirmModal2 from "../../components-special/ConfirmModal";

const EditThemePage = () => {
  const { isLoading, themes, currentTheme, directions, songs, themeSongs } =
    useSelector((store) => store.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const thisTheme = themes.find((theme) => theme._id === currentTheme);
  const thisThemeSongs = _.filter(themeSongs, { themeId: currentTheme });

  const initialState = {
    name: thisTheme ? thisTheme.name : "",
    description: thisTheme ? thisTheme.description : "",
    anons: thisTheme ? thisTheme.anons : "",
  };

  useEffect(() => {
    if (!thisTheme) {
      navigate("/a-panel/");
    }
  }, [currentTheme]);

  useEffect(() => {
    dispatch(getSongs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThemeSongs());
  }, [dispatch]);

  const [values, setValues] = useState(initialState);
  const [direction, setDirection] = useState("");
  const [directionList, setDirectionList] = useState([]);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        editTheme({
          name: values.name,
          description: values.description,
          anons: values.anons,
          themeId: currentTheme,
        })
      );
    }
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteTheme({ id: currentTheme }));
      setTimeout(() => {
        navigate("/a-panel/all_themes/");
      }, 1000);
    }
  };

  const deleteThemeHandler = () => {
    // dispatch(deleteTheme({ id: currentTheme }));
    // setTimeout(() => {
    //   navigate("/a-panel/all_themes/");
    // }, 1000);
    setIsConfirmModal(true);
  };

  const directionHandler = (direction) => {
    setDirection(direction);
  };

  const addListHandler = (data) => {
    setDirectionList([...directionList, data]);
  };

  const removeListHandler = (name) => {
    setDirectionList(directionList.filter((l) => l.name !== name));
  };

  const themeSongHandler = () => {
    if (!direction || directionList.length === 0 || !currentTheme) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        createThemeSongs({
          name: direction,
          themeId: currentTheme,
          songs: directionList,
        })
      );
      setDirectionList([]);
      setDirection("");
    }
  };
  const songHandler = (id) => {
    dispatch(currentSongHandler(id));
    navigate("/a-panel/edit_song/");
  };

  const [isConfirmModal2, setIsConfirmModal2] = useState(false);
  const [currentId2, setCurrentId2] = useState(false);

  const confirmHandler2 = (c) => {
    if (c === false) {
      setIsConfirmModal2(false);
    } else {
      setIsConfirmModal2(false);
      dispatch(deleteThemeSongs({ id: currentId2 }));
    }
  };

  const deleteThemeSongHandler2 = (_id) => {
    // dispatch(deleteThemeSongs({ id: _id }))
    setIsConfirmModal2(true);
    setCurrentId2(_id);
  };

  return (
    <div>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      {isConfirmModal2 && <ConfirmModal2 passState={confirmHandler2} />}
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        {isLoading && <Loading2 />}
        {thisTheme && !isLoading && (
          <main>
            <div className="header">
              <h4>{thisTheme.name}</h4>
            </div>
            <form onSubmit={onSubmit}>
              <div className="content">
                <div className="info_1">
                  <div className="in">
                    <label>Название темы </label>
                    <InputSmall
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="text-info">
                  <div className="description">
                    <label>Описание</label>
                    <Textarea
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="description2">
                  <label>Анонс </label>
                  <Textarea
                    type="text"
                    name="anons"
                    value={values.anons}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="actions">
                <Button text="Coхранить" type="submit" />
                {/* <Delete text="Удалить" onClick={deleteSongHandler} /> */}
                {thisThemeSongs.length === 0 && (
                  <Delete text="Удалить" onClick={deleteThemeHandler} />
                )}
                {thisThemeSongs.length !== 0 && (
                  <Delete
                    text="Удалить"
                    onClick={() =>
                      toast.error(
                        "Необходимо удалить все песни и игры в тукущей теме"
                      )
                    }
                  />
                )}
              </div>
            </form>

            <div className="content2">
              <Select
                passState={directionHandler}
                data={directions}
                def="Выберите направление"
              />
              <div className="songs">
                <div className="all-songs">
                  <p>Каталог песен и игр</p>
                  {songs.map((song) => {
                    const { _id, name } = song;
                    return (
                      <div className="song" key={_id}>
                        <FaPlus
                          onClick={() =>
                            addListHandler({ name: name, id: _id })
                          }
                        />
                        <p>{name}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="added-songs">
                  <p>{direction}</p>
                  {_.uniqBy(directionList, "name").map((list) => {
                    const { id, name } = list;
                    return (
                      <div className="added" key={id}>
                        <FaMinus onClick={() => removeListHandler(name)} />
                        <p>{name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="actions">
                <Button
                  text="Добавить"
                  type="button"
                  onClick={themeSongHandler}
                />
              </div>
            </div>
            <div className="group-aims">
              <div className="aim">
                <p className="info">Все направления</p>
                {thisThemeSongs?.map((t) => {
                  const { _id, name, songs } = t;
                  return (
                    <div key={_id}>
                      <div className="delete2">
                        <p className="name2">{name}</p>
                        <p
                          className="delete"
                          onClick={
                            // () =>
                            // dispatch(deleteThemeSongs({ id: _id }))
                            () => deleteThemeSongHandler2(_id)
                          }
                        >
                          удалить
                        </p>
                      </div>
                      {songs.map((song, index) => (
                        <div className="song2" key={index}>
                          <p>{index + 1}. </p>
                          <p
                            className="song-link"
                            onClick={() => songHandler(song.id)}
                          >
                            {song.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </main>
        )}
      </Wrapper>
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 400px;
    padding: 1rem;
  }
  .description2 {
    display: flex;
    flex-direction: column;
    height: 200px;
    padding: 1rem;
  }
  img {
    margin: 1rem;
    width: 250px;
  }
  .video {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    /* height: 300px; */
  }
  .video-name {
    display: flex;
    align-items: center;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
    div {
      margin: 0 1rem;
    }
  }
  .songs {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
  .song {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--blue-1);
      }
    }
  }
  .added {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--red-1-);
      }
    }
  }
  .content2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .group-aims {
    width: 85vw;
    margin: 1rem;
  }
  .song-link {
    color: var(--blue-1);
    margin: 0.25rem;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .info {
    margin-bottom: 1rem;
  }
  .song2 {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
  .name2 {
  }
  .delete2 {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    .delete {
      margin-left: 1rem;
      font-size: 0.8rem;
      color: var(--purple-1);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      /* flex-direction: row; */
    }
    .info_1 {
      width: 400px;
    }
    .description {
      width: 500px;
    }
    input {
      width: 468px;
    }
    .content2 {
      justify-content: center;
      align-items: center;
      width: 85vw;
    }
    .songs {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 2rem;
    }
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
    input {
      width: 668px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
    input {
      width: 768px;
    }
  }

  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: center;
    padding: 0.5rem;
    align-items: center;
    height: 50px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;
export default EditThemePage;
