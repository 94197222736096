import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  currentExHandler,
  deleteAim,
  editAim,
} from "../../features/adminSlice";
import { useNavigate } from "react-router-dom";
import InputSmall from "../../components-special/InputSmall";
import Button from "../../components-special/Button";
import EditAim from "./EditAim";
import ConfirmModal from "../../components-special/ConfirmModal";

const Aim = ({ name, list, id, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const extraRef = useRef(null);
  const { exs } = useSelector((store) => store.admin);

  const [ex, setEx] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit && extraRef.current) {
      extraRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isEdit]);

  useEffect(() => {
    const thisEx = exs?.find((e) => e.name === ex);

    if (thisEx) {
      dispatch(currentExHandler(thisEx._id));
      navigate("/a-panel/edit_ex/");
    }
  }, [ex]);

  const editHandler = () => {
    setIsEdit(!isEdit);
  };

  const [value, setValues] = useState(name);

  const changeHandler = (e) => {
    setValues(e.target.value);
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentId, setCurrentId] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteAim({ id: id }));
    }
  };

  const deleteClassHandler = (_id) => {
    setIsConfirmModal(true);
    setCurrentId(_id);
  };

  return (
    <>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Wrapper>
        <div className="aim">
          <p>{name}</p>
          <p onClick={editHandler} className="edit">
            редактировать
          </p>
          <p onClick={deleteClassHandler} className="delete">
            удалить
          </p>
        </div>
        {isEdit && (
          <div className="extra" ref={extraRef}>
            <InputSmall type="text" value={value} onChange={changeHandler} />
            <div className="actions2">
              <Button
                onClick={() => dispatch(editAim({ id: id, name: value }))}
                text="Сохранить название цели"
                type="button"
              />
            </div>

            <div className="editaim">
              <EditAim id={id} list={list} />
            </div>
          </div>
        )}
        <div className="exs">
          {list.map((l, index) => {
            return (
              <div className="ex" key={index} onClick={() => setEx(l)}>
                <p>{index + 1}. </p>
                <p onClick={() => setEx(l)} className="list">
                  {l}
                </p>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  .aim {
    display: flex;
    align-items: center;
    .delete {
      margin-left: 1rem;
      font-size: 0.8rem;
      color: var(--purple-1);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    .edit {
      margin-left: 1rem;
      font-size: 0.8rem;
      color: var(--green-1);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }

  .ex {
    display: flex;
    margin-left: 1rem;
    .list {
      color: var(--blue-1);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  .actions2 {
    display: flex;
    div {
      margin: 0.5rem 1rem;
    }
  }
  .delete {
    margin-left: 1rem;
    font-size: 0.8rem;
    color: var(--purple-1);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .extra {
    border: 1px solid var(--blue-0);
    border-radius: 15px;
    /* padding: 10px; */
    box-shadow: var(--shadow-2);
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .extra {
      margin: 10px;
      padding: 20px;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Aim;
