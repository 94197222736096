import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Register, PersonalArea, ProtectedRoute } from "./pages/index-pages";
import ProtectedAdmin from "./pages/ProtectedAdmin";
import AdminLogin from "./pages/admin-pages/AdminLogin";
import AdminPage from "./pages/admin-pages/AdminPage";
import AddEmpolyee from "./pages/admin-pages/AddEmployee";
import AllUsers from "./pages/admin-pages/AllUsers";
import { Toaster } from "react-hot-toast";
import UserSettingsPage from "./pages/UserSettingsPage";
import AdminGroups from "./pages/admin-pages/AdminGroups";
import AddGroup from "./pages/admin-pages/AddGroup";
import AllGroups from "./pages/AllGroups";
import GroupPage from "./pages/GroupPage";
import EditGroupPage from "./pages/admin-pages/EditGroupPage";
import DirectionPage from "./pages/admin-pages/DirectionPage";
import AddEx from "./pages/admin-pages/AddEx";
import AllEx from "./pages/admin-pages/AllEx";
import EditExPage from "./pages/admin-pages/EditExPage";
import ExPage from "./pages/ExPage";
import Aim from "./pages/admin-pages/AimPage";
import AddSong from "./pages/admin-pages/AddSong";
import AllSong from "./pages/admin-pages/AllSong";
import EditSongPage from "./pages/admin-pages/EditSongPage";
import AllSongsUser from "./pages/AllSongsUser";
import SongPage from "./pages/SongPage";
import AddTheme from "./pages/admin-pages/AddTheme";
import AllThemes from "./pages/admin-pages/AllThemes";
import EditThemePage from "./pages/admin-pages/EditThemePage";
import AllThemesUser from "./pages/AllThemesUser";
import ThemePage from "./pages/ThemePage";
import AddBase from "./pages/admin-pages/AddBase";
import AllBases from "./pages/admin-pages/AllBases";
import EditBasePage from "./pages/admin-pages/EditBasePage";
import AllBasesUser from "./pages/AllBasesUser";
import BasePage from "./pages/BasePage";
import ClassesPage from "./pages/admin-pages/ClassesPage";
import AllExUser from "./pages/AllExUser";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <PersonalArea />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/" element={<PersonalArea />} />
          </Route>

          <Route
            path="/personal-area/user_settings/"
            element={
              <ProtectedRoute>
                <UserSettingsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path="/personal-area/user_settings/"
              element={<UserSettingsPage />}
            />
          </Route>

          <Route
            path="/personal-area/groups/"
            element={
              <ProtectedRoute>
                <AllGroups />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/groups/" element={<AllGroups />} />
          </Route>

          <Route
            path="/personal-area/group/"
            element={
              <ProtectedRoute>
                <GroupPage />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/group/" element={<GroupPage />} />
          </Route>

          <Route
            path="/personal-area/all_songs/"
            element={
              <ProtectedRoute>
                <AllSongsUser />
              </ProtectedRoute>
            }
          >
            <Route
              path="/personal-area/all_songs/"
              element={<AllSongsUser />}
            />
          </Route>

          <Route
            path="/personal-area/all-exercise/"
            element={
              <ProtectedRoute>
                <AllExUser />
              </ProtectedRoute>
            }
          >
            <Route
              path="/personal-area/all-exercise/"
              element={<AllExUser />}
            />
          </Route>

          <Route
            path="/personal-area/exercise/"
            element={
              <ProtectedRoute>
                <ExPage />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/exercise/" element={<ExPage />} />
          </Route>

          <Route
            path="/personal-area/song/"
            element={
              <ProtectedRoute>
                <SongPage />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/song/" element={<SongPage />} />
          </Route>

          <Route
            path="/personal-area/all_themes/"
            element={
              <ProtectedRoute>
                <AllThemesUser />
              </ProtectedRoute>
            }
          >
            <Route
              path="/personal-area/all_themes/"
              element={<AllThemesUser />}
            />
          </Route>

          <Route
            path="/personal-area/theme/"
            element={
              <ProtectedRoute>
                <ThemePage />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/theme/" element={<ThemePage />} />
          </Route>

          <Route
            path="/personal-area/all_bases/"
            element={
              <ProtectedRoute>
                <AllBasesUser />
              </ProtectedRoute>
            }
          >
            <Route
              path="/personal-area/all_bases/"
              element={<AllBasesUser />}
            />
          </Route>

          <Route
            path="/personal-area/base/"
            element={
              <ProtectedRoute>
                <BasePage />
              </ProtectedRoute>
            }
          >
            <Route path="/personal-area/base/" element={<BasePage />} />
          </Route>

          <Route
            path="/a-panel/"
            element={
              <ProtectedAdmin>
                <AdminPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/" element={<AdminPage />} />
          </Route>

          <Route
            path="/a-panel/add_employee/"
            element={
              <ProtectedAdmin>
                <AddEmpolyee />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_employee/" element={<AddEmpolyee />} />
          </Route>

          <Route
            path="/a-panel/all_users/"
            element={
              <ProtectedAdmin>
                <AllUsers />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_users/" element={<AllUsers />} />
          </Route>

          <Route
            path="/a-panel/all_groups/"
            element={
              <ProtectedAdmin>
                <AdminGroups />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_groups/" element={<AdminGroups />} />
          </Route>

          <Route
            path="/a-panel/add_group/"
            element={
              <ProtectedAdmin>
                <AddGroup />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_group/" element={<AddGroup />} />
          </Route>

          <Route
            path="/a-panel/group/"
            element={
              <ProtectedAdmin>
                <EditGroupPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/group/" element={<EditGroupPage />} />
          </Route>

          <Route
            path="/a-panel/directions/"
            element={
              <ProtectedAdmin>
                <DirectionPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/directions/" element={<DirectionPage />} />
          </Route>

          <Route
            path="/a-panel/add_ex/"
            element={
              <ProtectedAdmin>
                <AddEx />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_ex/" element={<AddEx />} />
          </Route>

          <Route
            path="/a-panel/all_ex/"
            element={
              <ProtectedAdmin>
                <AllEx />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_ex/" element={<AllEx />} />
          </Route>

          <Route
            path="/a-panel/edit_ex/"
            element={
              <ProtectedAdmin>
                <EditExPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/edit_ex/" element={<EditExPage />} />
          </Route>

          <Route
            path="/a-panel/aim/"
            element={
              <ProtectedAdmin>
                <Aim />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/aim/" element={<Aim />} />
          </Route>

          <Route
            path="/a-panel/add_song/"
            element={
              <ProtectedAdmin>
                <AddSong />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_song/" element={<AddSong />} />
          </Route>

          <Route
            path="/a-panel/all_songs/"
            element={
              <ProtectedAdmin>
                <AllSong />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_songs/" element={<AllSong />} />
          </Route>

          <Route
            path="/a-panel/edit_song/"
            element={
              <ProtectedAdmin>
                <EditSongPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/edit_song/" element={<EditSongPage />} />
          </Route>

          <Route
            path="/a-panel/add_theme/"
            element={
              <ProtectedAdmin>
                <AddTheme />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_theme/" element={<AddTheme />} />
          </Route>

          <Route
            path="/a-panel/all_themes/"
            element={
              <ProtectedAdmin>
                <AllThemes />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_themes/" element={<AllThemes />} />
          </Route>

          <Route
            path="/a-panel/edit_theme/"
            element={
              <ProtectedAdmin>
                <EditThemePage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/edit_theme/" element={<EditThemePage />} />
          </Route>

          <Route
            path="/a-panel/add_base/"
            element={
              <ProtectedAdmin>
                <AddBase />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/add_base/" element={<AddBase />} />
          </Route>

          <Route
            path="/a-panel/all_bases/"
            element={
              <ProtectedAdmin>
                <AllBases />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/all_bases/" element={<AllBases />} />
          </Route>

          <Route
            path="/a-panel/edit_base/"
            element={
              <ProtectedAdmin>
                <EditBasePage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/edit_base/" element={<EditBasePage />} />
          </Route>

          <Route
            path="/a-panel/classes/"
            element={
              <ProtectedAdmin>
                <ClassesPage />
              </ProtectedAdmin>
            }
          >
            <Route path="/a-panel/classes/" element={<ClassesPage />} />
          </Route>

          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        <Toaster
          position={"top-right"}
          reverseOrder={false}
          toastOptions={{
            duration: 4000,
            style: {
              padding: "1rem",
              // backgroundColor: "#ffdfff",
              fontSize: "1rem",
              // color: "#17132a",
              // borderColor: "#ff00ff",
              // border: "2px solid #ff00ff",
            },
          }}
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
