import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import Button from "../../components-special/Button";
import { createSong } from "../../features/adminSlice";

const initialState = {
  name: "",
  description: "",
};

const AddSong = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        createSong({ name: values.name, description: values.description })
      );

      setTimeout(() => {
        navigate("/a-panel/all_songs/");
      }, 1000);
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Новая песня/игра</h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="content">
              <div className="info_1">
                <div className="in">
                  <label>Название песни/игры</label>
                  <InputSmall
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="text-info">
                <div className="description">
                  <label>Описание</label>
                  <Textarea
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <Button text="Создать песню/игру" type="submit" />
            </div>
          </form>
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 1rem;
  }
  .instructor {
    height: 200px;
  }
  img {
    margin: 1rem;
    width: 250px;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      /* flex-direction: row; */
    }
    .info_1 {
      width: 400px;
    }
    input {
      width: 468px;
    }
    .description {
      width: 500px;
    }
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
    input {
      width: 668px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
    input {
      width: 768px;
    }
  }
  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;

export default AddSong;
