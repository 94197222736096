import customFetch from "../utils/adminAxios";
import { logOutAdmin } from "./adminSlice";

let isUnauthorizedMessageShown = false;

const handleApiError = (error, thunkAPI) => {
  if (error.response?.status === 401) {
    if (!isUnauthorizedMessageShown) {
      isUnauthorizedMessageShown = true;
      thunkAPI.dispatch(logOutAdmin());
      return thunkAPI.rejectWithValue(
        "Для входа в систему введите логин и пароль"
      );
    }
  } else {
    console.log(error.response?.data.msg);
    return thunkAPI.rejectWithValue(error.response?.data.msg);
  }
};

export const loginAdminThunk = async (url, admin, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, admin);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const remindAdminThunk = async (url, admin, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, admin);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const createUserThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getusersThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editUserThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createGroupThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getGroupsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const uploadVideoThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getVideoThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const updateGroupThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteVideoThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteGroupThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createDirectionThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getDirectionThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteDirectionThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createExThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getExThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const updateExThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteExThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createAimThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getAimThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteAimThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createSongThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editSongThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteSongThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createThemeThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getThemeThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editThemeThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteThemeThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createThemeSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getThemeSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteThemeSongsThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createBaseThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getBasesThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editBaseThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteBaseThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createWeekThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getWeeksThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editWeekThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteWeekThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createClassThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getClassesThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteClassThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const createWeekLevelThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getWLThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const getAllWLThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.get(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const updateAccessThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const updateLevelsAccessThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const deleteUserThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.delete(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const addWholeYearThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const add10WeeksThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.patch(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editAimThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};

export const editAimFromListThunk = async (url, info, thunkAPI) => {
  isUnauthorizedMessageShown = false;
  try {
    const resp = await customFetch.post(url, info);
    return resp.data;
  } catch (error) {
    return handleApiError(error, thunkAPI);
  }
};
