import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import Video from "../components/userGroups/Video";
import {
  getVideo,
  getComments,
  currentGroupHandler,
} from "../features/user/userSlise";
import CommentsEx from "../components/Comments/CommentsEx";

import _ from "lodash";

const { REACT_APP_SPACE } = process.env;

const ExPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentEx, video, exs, aims, groups, user } = useSelector(
    (store) => store.user
  );

  const thisEx = exs.find((ex) => ex._id === currentEx);
  let thisVideo;
  thisVideo = _.filter(video, { groupId: currentEx });

  const [groupId, setGroupId] = useState();

  const aims_include = _.filter(aims, function (o) {
    return _.includes(o.list, thisEx?.name);
  });

  const resultArray = aims_include.map((item) => ({
    groupId: item.groupId,
    list: item.list,
  }));

  const data = _.filter(resultArray, function (o) {
    return _.includes(o.list, thisEx?.name);
  });

  const data2 = _.uniqBy(data, "groupId");

  useEffect(() => {
    if (!currentEx) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (currentEx) {
      dispatch(getVideo({ groupId: currentEx }));
    }
  }, [currentEx]);

  useEffect(() => {
    if (currentEx) {
      dispatch(getComments({ id: currentEx }));
    }
  }, [currentEx]);

  useEffect(() => {
    if (groupId) {
      dispatch(currentGroupHandler(groupId));
      navigate("/personal-area/group/");
    }
  }, [groupId]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.AExs === true && (
        <Wrapper>
          {currentEx && (
            <main>
              <div className="header">
                <h4>{thisEx?.name}</h4>
              </div>
              <div className="content">
                <div className="about">
                  <p className="info">Описание</p>
                  <p>{thisEx?.description}</p>
                  <div className="video">
                    {thisVideo.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                      />
                    ))}
                  </div>
                </div>
                <div className="comments">
                  {user.ALevels === true && (
                    <div className="groups">
                      <p>Уровни</p>
                      {data2.map((d, index) => {
                        const thisGroup = groups.find(
                          (g) => g._id === d.groupId
                        );
                        const currentLevel = user.Access.find(
                          (a) => a?.levelId === thisGroup._id
                        );

                        return (
                          <div key={index}>
                            {currentLevel?.als4 === true && (
                              <div
                                className="group"
                                onClick={() => setGroupId(d.groupId)}
                              >
                                {thisGroup?.name}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="logo">
                    <img src={`${REACT_APP_SPACE}/${thisEx.image}`} alt="img" />
                    {/* <CommentsEx /> */}
                  </div>
                </div>
              </div>
            </main>
          )}
        </Wrapper>
      )}
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .label {
    font-size: 1.2rem;
    color: var(--clr-grey-5);
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .about {
    width: 100%;
    padding: 1rem 0;

    p {
      white-space: pre-wrap;
      user-select: none;
    }
  }
  .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .logo {
    margin: 1rem;
    img {
      width: 100%;
      max-width: 250px;
    }
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .name {
    cursor: pointer;
    margin-left: 1rem;
  }
  .groups {
    margin-left: 1rem;
  }
  .group {
    margin-left: 1rem;
    color: var(--blue-1);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about {
      width: 600px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1200px) {
    .about {
      width: 800px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .about {
      width: 900px;
    }
    .comments {
      width: 300px;
    }
  }
`;
export default ExPage;
