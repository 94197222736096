import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { currentExHandler } from "../../features/user/userSlise";
import { useNavigate } from "react-router-dom";

const AimUser = ({ name, list, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { aims, exs } = useSelector((store) => store.user);
  const thisAim = aims.find((a) => a._id === id);
  console.log("thisAim", thisAim);
  // console.log("name", name);
  console.log("list", list);

  // console.log(name);
  const [ex, setEx] = useState();

  useEffect(() => {
    const thisEx = exs.find((e) => e.name === ex);
    if (thisEx) {
      dispatch(currentExHandler(thisEx._id));
      navigate("/personal-area/exercise/");
    }
  }, [ex]);
  return (
    <Wrapper>
      <div className="aim">
        <p>{name.substring(3)}</p>
        {/* <p>{name}</p> */}
      </div>
      <div className="exs">
        {thisAim?.list.map((l, index) => {
          return (
            <div className="ex" key={index}>
              <p>{index + 1}. </p>
              <p onClick={() => setEx(l)} className="list">
                {l}
              </p>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .aim {
  }
  .ex {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    .list {
      color: var(--blue-1);
      margin: 0.25rem;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AimUser;
