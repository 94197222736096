/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { useDispatch, useSelector } from "react-redux";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import {
  getVideo,
  updateGroup,
  deleteGroup,
  uploadVideo,
  getEx,
  createAim,
} from "../../features/adminSlice";
import { useNavigate } from "react-router-dom";
import Select from "../../components-special/Select";
import Checkbox from "../../components-special/Checkbox";
import Video from "../../components/adminGroups/Video";
import _ from "lodash";
import Button from "../../components-special/Button";
import toast from "react-hot-toast";
import Delete from "../../components-special/Delete";
import Loading from "../../components/Loading";
import { FaMinus } from "react-icons/fa";
import AimAdmin from "../../components/adminAim/AimAdmin";
import Aim from "../../components/userGroups/Aim";
import Loading2 from "../../components/Loading2";
import ConfirmModal from "../../components-special/ConfirmModal";

const { REACT_APP_SPACE } = process.env;

const EditGroupPage = () => {
  const { groups, currentGroup, videos, isLoading, directions, aims, exs } =
    useSelector((store) => store.admin);

  const thisGroup = groups?.find((g) => g._id === currentGroup);

  const initialState = {
    name: thisGroup ? thisGroup.name : "",
    description: thisGroup ? thisGroup.description : "",
    instructor: thisGroup ? thisGroup.instructor : "",
    direction: thisGroup ? thisGroup.label : "",
    aim_name: "",
  };

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let thisVideo;
  let thisVideo1;
  let thisVideo2;
  let aims2;
  let aims3;
  // const sortedGroup = _.sortBy(groups, [(item) => item.name[1]]);

  thisVideo = _.filter(videos, { groupId: currentGroup });
  thisVideo1 = _.filter(thisVideo, { label: "для описания" });
  thisVideo2 = _.filter(thisVideo, { label: "для требования к инструктору" });
  aims2 = _.filter(aims, { groupId: currentGroup });
  aims3 = _.sortBy(aims2, (item) => item.name.substring(0, 2));
  console.log("aims3", aims3);

  // console.log(aims3);

  useEffect(() => {
    if (!thisGroup) {
      navigate("/a-panel/");
    }
  }, [currentGroup]);

  useEffect(() => {
    dispatch(getVideo({ groupId: currentGroup }));
  }, [currentGroup]);

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  const [label, setLabel] = useState(thisGroup ? thisGroup.label : "");
  const [image, setImage] = useState();
  const [videoDesc, setVideoDesc] = useState();
  const [choose, setChoose] = useState("");

  const [previewURL, setpreviewURL] = useState();

  const [list, setList] = useState([]);

  const filePickerRef = useRef();
  const videoDescPickerRef = useRef();

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const pickVideoDescHandler = () => {
    videoDescPickerRef.current.click();
  };

  const pickedHandler = (e) => {
    let pickedFile;
    if (e.target.files && e.target.files.length === 1) {
      pickedFile = e.target.files[0];
      setImage(pickedFile);
      return;
    }
  };

  const pickedVideoDescHandler = (e) => {
    let pickedVideoDesc;
    if (e.target.files && e.target.files.length === 1) {
      pickedVideoDesc = e.target.files[0];
      setVideoDesc(pickedVideoDesc);
      return;
    }
  };

  useEffect(() => {
    if (!image) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setpreviewURL(fileReader.result);
    };
    fileReader.readAsDataURL(image);
  }, [image]);

  const formData = new FormData();
  formData.append("image", image ? image : "false");
  formData.append("name", values.name);
  formData.append("description", values.description);
  formData.append("label", label);
  formData.append("instructor", values.instructor);
  formData.append("groupId", currentGroup);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(updateGroup(formData));
      dispatch(
        createAim({
          name: values.aim_name,
          list: _.uniq(list),
          groupId: currentGroup,
        })
      );
      setList([]);
      // setValues(initialState);
    }
  };

  const directionHandler = (data) => {
    setLabel(data);
  };

  const formDataVideo = new FormData();
  formDataVideo.append("video", videoDesc ? videoDesc : "false");
  formDataVideo.append("groupId", currentGroup);
  formDataVideo.append("choose", choose);

  const videoSubmitHandler = () => {
    if (!choose) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(uploadVideo(formDataVideo));
    }
  };

  const chooseHandler = (data) => {
    setChoose(data);
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteGroup({ id: currentGroup }));
      setTimeout(() => {
        navigate("/a-panel/all_groups/");
      }, 1000);
    }
  };

  const deleteGroupHandler = () => {
    // dispatch(deleteGroup({ id: currentGroup }));
    // setTimeout(() => {
    //   navigate("/a-panel/all_groups/");
    // }, 1000);
    setIsConfirmModal(true);
  };

  const addListHandler = (data) => {
    setList([...list, data]);
  };

  const removeListHandler = (l) => {
    setList(list.filter((n) => n !== l));
  };
  return (
    <div>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        {isLoading && <Loading2 />}
        {thisGroup && !isLoading && (
          <main>
            <div className="header">
              <h4>{thisGroup.name}</h4>
              <p className="label">{thisGroup.label}</p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="content">
                <div className="info_1">
                  <div className="in">
                    <label>Название уровня</label>
                    <InputSmall
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    <img
                      src={`${REACT_APP_SPACE}/${thisGroup.image}`}
                      alt="img"
                    />
                    <div className="effect" onClick={pickImageHandler}>
                      <p>новый символ</p>
                    </div>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".img,.png,.jpeg,.jpg"
                      ref={filePickerRef}
                      onChange={pickedHandler}
                    />
                    <img src={previewURL} />
                  </div>
                  <div className="in">
                    <label>Направление</label>
                    <div className="select">
                      <Select
                        passState={directionHandler}
                        data={directions}
                        def={thisGroup.label}
                      />
                    </div>
                  </div>
                  <div className="in">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mov,.mp4,.pdf"
                      ref={videoDescPickerRef}
                      onChange={pickedVideoDescHandler}
                    />
                    <label>Загрузка видео</label>
                    <div className="effect">
                      <p onClick={pickVideoDescHandler}>добавить видео</p>
                    </div>
                    {videoDesc && (
                      <div>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div>
                            <p className="video-name">
                              Имя файла: {videoDesc.name}
                            </p>
                            <p className="video-name">
                              Размер файла:{" "}
                              {_.ceil(videoDesc.size / 1024 / 1024)} Mb{" "}
                            </p>
                            <div className="checkbox-group">
                              <Checkbox
                                label="для описания"
                                passState={chooseHandler}
                                indicator={choose}
                              />
                              <Checkbox
                                label="для требования к инструктору"
                                passState={chooseHandler}
                                indicator={choose}
                              />
                            </div>
                            <Button
                              text="Загрузить"
                              onClick={videoSubmitHandler}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="text_info">
                  <div className="description">
                    <label>Описание</label>
                    <Textarea
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    {thisVideo1.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                        id={v._id}
                      />
                    ))}
                  </div>
                  <div className="description instructor">
                    <label>Требования к инструктору</label>
                    <Textarea
                      // rows="1"
                      type="text"
                      name="instructor"
                      value={values.instructor}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    {thisVideo2.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                        id={v._id}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="in">
                <p className="info">Название новой цели</p>
                <InputSmall
                  placeholder="новая цель"
                  type="text"
                  name="aim_name"
                  value={values.aim_name}
                  onChange={changeHandler}
                />
              </div>
              {values.aim_name && (
                <div className="content2">
                  <div className="in list list-height">
                    <p className="info">Список упражнений</p>
                    {exs?.map((ex) => (
                      <AimAdmin
                        key={ex._id}
                        name={ex.name}
                        passList={addListHandler}
                      />
                    ))}
                  </div>
                  <div className="in list">
                    <p className="info">Добавленные упражнения</p>
                    {list.length !== 0 && (
                      <div>
                        {_.uniq(list).map((l, index) => {
                          return (
                            <div className="added-item" key={index}>
                              <FaMinus onClick={() => removeListHandler(l)} />
                              <p>{l}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="actions">
                <Button text="Coхранить" type="submit" />
                {aims2.length === 0 && (
                  <Delete text="Удалить" onClick={deleteGroupHandler} />
                )}
                {aims2.length !== 0 && (
                  <Delete
                    text="Удалить"
                    onClick={() =>
                      toast.error("Необходимо удалить все цели уровня")
                    }
                  />
                )}
              </div>
            </form>
            {/* ___________________________________________________ */}
            <div className="group-aims">
              <div className="aim">
                <p className="info">Цели учеников</p>
                {aims3.map((aim, index) => (
                  <Aim
                    key={aim._id}
                    name={aim.name}
                    list={aim.list}
                    id={aim._id}
                    index={index}
                  />
                ))}
              </div>
            </div>
            {/* ___________________________________________________ */}
          </main>
        )}
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input,
  .select {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 1rem;
  }
  .instructor {
    height: 200px;
  }
  img {
    margin: 1rem;
    width: 250px;
  }
  .video {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    /* height: 300px; */
  }
  .video-name {
    display: flex;
    align-items: center;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .checkbox-group {
    margin: 1rem 0;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
    div {
      margin: 0 1rem;
    }
  }
  .aims {
    display: flex;
    justify-content: space-between;
  }
  .del {
    display: flex;
    margin: 0.5rem;
    align-items: center;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--purple-2);
      }
    }
  }
  .content2 {
    display: flex;
    flex-direction: column;
  }
  .added-item {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    svg {
      margin: 0 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      transition: 0.6s;
      :hover {
        color: var(--purple-2);
      }
    }
  }
  .group-aims {
    width: 85vw;
    margin: 1rem;
    margin-bottom: 2rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input,
    .select {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
    }
    .info_1 {
      width: 400px;
    }
    .description {
      width: 500px;
    }
    .content2 {
      flex-direction: row;
      justify-content: space-between;
      width: 80vw;
    }
  }
  .list-height {
    height: 400px;
    overflow: auto;
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
  }
  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: center;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;
export default EditGroupPage;
