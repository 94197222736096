import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Ex from "../../components/adminEx/Ex";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import Input2 from "../../components-special/Input2";
import { getEx } from "../../features/adminSlice";
import _ from "lodash";

const initialState = {
  search: "",
};
const AllEx = () => {
  const { exs } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const sortedExs = _.sortBy(exs, ["name"]);

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Все упражнения</h4>
          </div>
          <div className="search">
            <Input2
              type="text"
              name="search"
              value={values.search.toLowerCase()}
              onChange={changeHandler}
            />
          </div>
          {sortedExs?.map((ex) => (
            <Ex
              key={ex._id}
              id={ex._id}
              name={ex.name}
              search={values.search}
            />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default AllEx;

// const exs = [
//   { id: "e213e2", name: "сымсцс" },
//   { id: "e213w2", name: "сцусцсцс" },
//   { id: "3213e2", name: "амусцс" },
//   { id: "e243e2", name: "гсцуцс" },
// ];
