import React, { useState } from "react";
import styled from "styled-components";
import CheckboxAccess from "../../components-special/CheckboxAccess";
import AccessLevel from "./AccessLevel";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../components-special/Button";
import { updateAccess, updateLevelsAccess } from "../../features/adminSlice";

const Access = ({ id, ALevels, AExs, ASongs, AThemes, ABase, ACcess }) => {
  const { groups } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const [accessLevels, setAccessLevels] = useState(ALevels || false);
  const [accessExs, setAccessExs] = useState(AExs || false);
  const [accessSongs, setAccessSongs] = useState(ASongs || false);
  const [accessThemes, setAccessThemes] = useState(AThemes || false);
  const [accessBase, setAccessBase] = useState(ABase || false);

  const [al, setAl] = useState(ACcess);

  const levelsHandler = (data) => {
    setAccessLevels(data);
  };

  const exsHandler = (data) => {
    setAccessExs(data);
  };

  const songsHandler = (data) => {
    setAccessSongs(data);
  };

  const themesHandler = (data) => {
    setAccessThemes(data);
  };

  const baseHandler = (data) => {
    setAccessBase(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateAccess({
        userId: id,
        ALevels: accessLevels ? true : false,
        AExs: accessExs ? true : false,
        ASongs: accessSongs ? true : false,
        AThemes: accessThemes ? true : false,
        ABase: accessBase ? true : false,
      })
    );
    dispatch(updateLevelsAccess({ userId: id, al }));
  };

  const alHandler = (d) => {
    const elementIndex = al?.findIndex((a) => a?.name === d?.name);
    if (elementIndex === -1) {
      setAl([...al, d]);
    } else {
      const updatedAl = [...al];
      updatedAl[elementIndex] = d;
      setAl(updatedAl);
    }
  };

  return (
    <Wrapper>
      <h4>Права доступа</h4>
      <form onSubmit={onSubmit}>
        <div className="access">
          <div className="global-access">
            <div className="box">
              <CheckboxAccess
                passState={levelsHandler}
                label="Уровни"
                indicator={accessLevels}
              />
            </div>
            <div className="box">
              <CheckboxAccess
                passState={exsHandler}
                label="Упражнения"
                indicator={accessExs}
              />
            </div>
            <div className="box">
              <CheckboxAccess
                passState={songsHandler}
                label="Песни и игры"
                indicator={accessSongs}
              />
            </div>
            <div className="box">
              <CheckboxAccess
                passState={themesHandler}
                label="Темы"
                indicator={accessThemes}
              />
            </div>
            <div className="box">
              <CheckboxAccess
                passState={baseHandler}
                label="База знаний"
                indicator={accessBase}
              />
            </div>
          </div>
          <div className="levels-access">
            {groups.map((g) => (
              <AccessLevel
                key={g._id}
                name={g.name}
                userId={id}
                levelId={g._id}
                passState={alHandler}
                ACcess={ACcess}
              />
            ))}
          </div>
        </div>
        <div className="actions">
          <Button text="Сохранить" type="submit" />
        </div>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  form {
    margin: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .access {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 80%;
  }
  .box {
    border: 1px dotted var(--blue-1);
    padding: 1rem;
    margin: 0.5rem;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .access {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Access;
