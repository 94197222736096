import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Levels from "./Levels";
import Button from "../../components-special/Button";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getWL,
  getEx,
  addWholeYear,
  add10Weeks,
} from "../../features/adminSlice";
import moment from "moment";

import Week from "./Week";
import _ from "lodash";

import Select4 from "../../components-special/Select4";
import { years } from "../../data/data";

const CalendarAdmin = () => {
  // console.log(moment().year());
  const currentWeekNumber = moment().isoWeek();

  const { weeks, currentWeek, isLoading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEx());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWL({ weekId: currentWeek }));
  }, [dispatch, currentWeek]);

  // const [values, setValues] = useState(initialState);
  const [value2, setValue2] = useState();
  const [value3, setValue3] = useState();

  // const changeHandler = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };
  const changeHandler2 = (e) => {
    setValue2(e.target.value);
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   const { year, week } = values;
  //   if (!year || !week) {
  //     toast.error("Введите все значения");
  //     return;
  //   } else {
  //     dispatch(createWeek({ year: year, week: week }));
  //   }
  // };

  const wholeYearHandler = () => {
    // const yearToCalculate = value2;
    // const firstDayOfYear = moment({ year: yearToCalculate, month: 0, day: 1 });
    // const lastDayOfYear = moment({ year: yearToCalculate, month: 11, day: 31 });
    // const numberOfWeeks = lastDayOfYear.diff(firstDayOfYear, "weeks") + 1;
    if (!value3) {
      return toast.error("Укажите год");
    }
    dispatch(addWholeYear({ year: _.toNumber(value3) }));
  };

  const yearHandler = (y) => {
    setValue3(y);
  };
  // _____________________________________________________________
  const weeksHandler = () => {
    if (weeks.length !== 0) {
      const groupedByYear = _.groupBy(weeks, "year");

      const maxYear = _.max(Object.keys(groupedByYear));

      const maxWeek = _.maxBy(groupedByYear[maxYear], "week").week;

      if (maxWeek <= 42) {
        const weeks2 = [];
        let week;
        let year;
        for (let i = 1; i <= 10; i++) {
          week = maxWeek + i;
          year = maxYear;
          weeks2.push({ year: parseInt(year), week: week });
        }

        dispatch(add10Weeks(weeks2));
      } else {
        const weeks2 = [];
        let week;
        let year;
        let t = 0;
        for (let i = 1; i <= 10; i++) {
          week = maxWeek + i;
          year = maxYear;
          if (week > 52) {
            t = t + 1;
            week = t;
            year = parseInt(maxYear) + 1;
          }
          weeks2.push({ year: parseInt(year), week: week });
        }
        dispatch(add10Weeks(weeks2));
      }
    } else {
      const currentYear = moment().year();

      if (currentWeekNumber <= 42) {
        const weeks2 = [];
        let week;
        let year;
        for (let i = 0; i < 10; i++) {
          week = currentWeekNumber + i;
          year = currentYear;
          weeks2.push({ year: parseInt(year), week: week });
        }

        dispatch(add10Weeks(weeks2));
      } else {
        const weeks2 = [];
        let week;
        let year;
        let t = 0;
        for (let i = 0; i < 10; i++) {
          week = currentWeekNumber + i;
          year = currentYear;
          if (week > 52) {
            t = t + 1;
            week = t;
            year = parseInt(currentYear) + 1;
            // year = parseInt(currentYear);
          }
          weeks2.push({ year: parseInt(year), week: week });
        }
        dispatch(add10Weeks(weeks2));
      }
    }

    // _____________________________________________________________
  };

  return (
    <>
      <Wrapper>
        {/* <form onSubmit={onSubmit}> */}
        <div>
          <div className="panel">
            {/* <div className="input-container">
            <Input2
              placeholder="текущий год"
              name="year"
              values={values.year}
              onChange={changeHandler}
            />
          </div> */}
            {/* <div className="input-container">
            <Input2
              placeholder="номер недели"
              name="week"
              values={values.week}
              onChange={changeHandler}
            />
          </div> */}
            {/* <div className="actions">
            <Button text="Добавить неделю" />
          </div> */}
            <div className="input-container">
              {/* <Input2
                placeholder="год"
                name="value2"
                values={value2}
                type="number"
                onChange={changeHandler2}
              /> */}
              <Select4 passState={yearHandler} data={years} />
            </div>
            <div className="actions">
              <Button
                text="Добавить весь год"
                type="button"
                onClick={wholeYearHandler}
              />
              <Button
                text="Добавить 10 недель"
                type="button"
                onClick={weeksHandler}
              />
            </div>
          </div>
          {/* </form> */}
        </div>
        <div className="calendar">
          <Levels />

          <div className="weeks">
            {weeks?.map((week) => (
              <Week
                key={week._id}
                weekId={week._id}
                week={week.week}
                year={week.year}
                currentWeekNumber={currentWeekNumber}
              />
            ))}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  .calendar {
    width: 90vw;
    margin-bottom: 2rem;
    display: flex;
  }
  .panel {
    margin: 1rem;
    display: flex;
    flex-direction: column;
  }
  .input-container {
    margin: 0.5rem 1rem;
  }
  .actions {
    display: flex;
    flex-direction: column;

    flex-wrap: wrap;
    div {
      margin: 0.5rem 1rem;
    }
  }
  .weeks {
    display: flex;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--blue-2);
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--blue-2);
      border-radius: 10px;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .panel {
      flex-direction: row;
    }
    .input-container {
      margin-top: 0;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    div {
      margin: 0 1rem;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default CalendarAdmin;
