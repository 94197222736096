import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/userNavigations/Navbar";
import Sidebar from "../components/userNavigations/Sidebar";
import SubMenu from "../components/userNavigations/Submenu";
import { currentSongHandler } from "../features/user/userSlise";

import _ from "lodash";

const ThemePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themes, currentTheme, themeSongs, user } = useSelector(
    (store) => store.user
  );

  const thisTheme = themes.find((theme) => theme._id === currentTheme);
  const thisThemeSongs = _.filter(themeSongs, { themeId: currentTheme });

  useEffect(() => {
    if (!currentTheme) {
      navigate("/");
    }
  }, [currentTheme]);

  const songHandler = (id) => {
    dispatch(currentSongHandler(id));
    navigate("/personal-area/song/");
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      {user.AThemes === true && (
        <Wrapper>
          {currentTheme && (
            <main>
              <div className="header">
                <h4>{thisTheme?.name}</h4>
              </div>
              <div className="content">
                <div className="about">
                  <p className="info">Описание</p>
                  <p>{thisTheme?.description}</p>
                  <p className="info">Анонс</p>
                  <p>{thisTheme?.anons}</p>
                  {user.ASongs && (
                    <div className="added-songs">
                      <p>Направления</p>
                      {thisThemeSongs.map((theme) => {
                        const { _id, name, songs } = theme;
                        return (
                          <div key={_id}>
                            {/* <p className="info">{name.substring(1)}</p> */}
                            <p className="info">{name}</p>
                            {songs.map((song, index) => (
                              <div className="song2" key={index}>
                                <p>{index + 1}. </p>
                                <p
                                  className="song-link"
                                  onClick={() => songHandler(song.id)}
                                >
                                  {song.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* <div className="comments">links</div> */}
              </div>
            </main>
          )}
        </Wrapper>
      )}
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  H4 {
    color: var(--blue-0);
  }
  .label {
    font-size: 1.2rem;
    color: var(--clr-grey-5);
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .about {
    width: 100%;
    padding: 1rem 0;

    p {
      white-space: pre-wrap;
      user-select: none;
    }
  }

  .comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .name {
    cursor: pointer;
    margin-left: 1rem;
  }
  .groups {
    margin-left: 1rem;
  }
  .group {
    margin-left: 1rem;
    color: var(--blue-1);
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .info {
    font-size: 1.3rem;
    color: var(--green-1);
    margin: 0.5rem 0;
  }
  .song2 {
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }
  .song-link {
    color: var(--blue-1);
    margin: 0.25rem;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .about {
      width: 600px;
    }

    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1200px) {
    .about {
      width: 800px;
    }
    .comments {
      width: 300px;
    }
  }
  @media (min-width: 1400px) {
    .about {
      width: 900px;
    }
    .comments {
      width: 300px;
    }
  }
`;
export default ThemePage;
