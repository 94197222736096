import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";

import { useSelector, useDispatch } from "react-redux";
import Group from "../../components/adminGroups/Group";
import Input2 from "../../components-special/Input2";
import { getGroups } from "../../features/adminSlice";

const initialState = {
  search: "",
};

const AdminGroups = () => {
  const dispatch = useDispatch();
  const { groups } = useSelector((store) => store.admin);
  console.log(groups);
  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Все уровни</h4>
          </div>
          <div className="search">
            <Input2
              type="text"
              name="search"
              value={values.search.toLowerCase()}
              onChange={changeHandler}
            />
          </div>
          {groups?.map((g) => (
            <Group key={g._id} id={g._id} search={values.search} />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1140px) {
  }
  @media (min-width: 1340px) {
  }
`;

export default AdminGroups;
