import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Base from "../../components/adminBase/Base";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import Input2 from "../../components-special/Input2";
import { getBases } from "../../features/adminSlice";

const initialState = {
  search: "",
};
const AllBases = () => {
  const { bases } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getBases());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        <main>
          <div className="header">
            <h4>Все знания</h4>
          </div>
          <div className="search">
            <Input2
              type="text"
              name="search"
              value={values.search.toLowerCase()}
              onChange={changeHandler}
            />
          </div>
          {bases?.map((base) => (
            <Base
              key={base._id}
              id={base._id}
              name={base.name}
              search={values.search}
            />
          ))}
        </main>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;

export default AllBases;
