import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Textarea from "../../components-special/TextArea";
import Button from "../../components-special/Button";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { createComment } from "../../features/user/userSlise";
import Comment from "./Comment";

const initialState = {
  comment: "",
};
const CommentsEx = () => {
  const { user, isLoading, currentSong, comments } = useSelector(
    (store) => store.user
  );
  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!values.comment) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        createComment({
          userId: user._id,
          groupId: currentSong, // ! important
          comment: values.comment,
          userLogin: user.login,
        })
      );
      setValues(initialState);
    }
  };

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Textarea
          rows="3"
          type="text"
          name="comment"
          value={values.comment}
          onChange={changeHandler}
        />
        <div className="actions">
          <Button text="Отправить" />
        </div>
        <div className="comments-container">
          {comments?.map((c) => (
            <Comment
              key={c._id}
              comment={c.comment}
              login={c.userLogin}
              createdAt={c.createdAt}
            />
          ))}
        </div>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 1rem;
  width: 80vw;
  margin: auto auto;
  .comments-container {
    overflow-y: auto;
    max-height: 500px;

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--blue-0);
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--blue-1);
      border-radius: 10px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    width: 100%;
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default CommentsEx;
