import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import UserLevels from "../../components/UserCalendar/UserLevels";
import UserWeek from "../../components/UserCalendar/UserWeek";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";

const UserCalendar = () => {
  const currentWeekNumber = moment().isoWeek();

  const { weeks, currentID } = useSelector((store) => store.user);

  const filteredWeeks = _.filter(weeks, (weekObj) => {
    const weekNumber = parseInt(weekObj.week);
    return (
      weekNumber >= currentWeekNumber - 1 && weekNumber <= currentWeekNumber + 4
    );
  });
  // console.log(filteredWeeks);

  const filteredWeeks2 = _.filter(
    filteredWeeks,
    (week) => week.year === moment().year()
  );
  // console.log(filteredWeeks2);

  const weeksRef = useRef(null);

  console.log("currentID", currentID);

  useEffect(() => {
    if (
      currentID === null ||
      currentID === undefined ||
      !currentID ||
      currentID === ""
    ) {
      setTimeout(() => {
        weeksRef.current.scrollBy({
          left: 200,
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [currentID, weeksRef]);

  return (
    <Wrapper>
      <main>
        <div className="header">
          <h4>Календарь</h4>
        </div>
        <div className="calendar">
          <UserLevels />
          <div className="weeks" ref={weeksRef}>
            {filteredWeeks2?.map((week) => (
              <UserWeek
                key={week._id}
                weekId={week._id}
                week={week.week}
                year={week.year}
                currentWeekNumber={currentWeekNumber}
              />
            ))}
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .calendar {
    width: 90vw;
    margin-bottom: 2rem;
    display: flex;
    border: 1px solid green;
  }
  .weeks {
    display: flex;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--blue-2);
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--blue-2);
      border-radius: 10px;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default UserCalendar;
