import React, { useState, useEffect } from "react";

import CheckboxAccess from "../../components-special/CheckboxAccess";

const ThisAL = ({ name, als, passState2, levelId }) => {
  const [als4, setAccessLevels] = useState(als || false);

  const levelHandler = (data) => {
    setAccessLevels(data);
  };

  useEffect(() => {
    passState2({
      levelId: levelId,
      name: name,
      als4,
    });
  }, [als4]);

  return (
    <div>
      <CheckboxAccess label={name} passState={levelHandler} indicator={als4} />
    </div>
  );
};

export default ThisAL;
