import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { currentGroupHandler } from "../../features/user/userSlise";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { REACT_APP_SPACE } = process.env;

const Group = ({ id, name, image, label, search }) => {
  const { groups, user } = useSelector((store) => store.user);

  const currentLevel = user.Access.find((a) => a?.levelId === id);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const groupRedirectHandler = () => {
    dispatch(currentGroupHandler(id));
    navigate("/personal-area/group/");
  };

  useEffect(() => {
    const text = name.toLowerCase();
    const searchText = search.toLowerCase();
    setVisible(text.includes(searchText));
  }, [search, name]);

  console.log("currentLevel", currentLevel);
  console.log("groups", groups);

  return (
    <>
      {currentLevel?.als4 === true && (
        <Wrapper onClick={groupRedirectHandler}>
          {visible && (
            <div className="content">
              <img src={`${REACT_APP_SPACE}/${image}`} alt="img" />
              <div className="group">
                <p className="name">{name}</p>
                <p className="label">{label}</p>
              </div>
            </div>
          )}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  .content {
    display: flex;
    align-items: center;
    margin: 10px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    transition: var(--transition2);
    padding: 0.5rem 1rem;
    cursor: pointer;

    :hover {
      background-color: var(--blue-3);
    }
  }
  img {
    width: 50px;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .group {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default Group;
