import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import Loading from "../../components/Loading";
import Video from "../../components/adminGroups/Video";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import _ from "lodash";
import {
  getVideo,
  deleteEx,
  uploadVideo,
  updateEx,
  currentGroupHandler,
} from "../../features/adminSlice";
import Loading2 from "../../components/Loading2";
import ConfirmModal from "../../components-special/ConfirmModal";

const { REACT_APP_SPACE } = process.env;

const EditExPage = () => {
  const { videos, isLoading, exs, currentEx, aims, groups } = useSelector(
    (store) => store.admin
  );

  let thisVideo;
  thisVideo = _.filter(videos, { groupId: currentEx }); // ! important groupId

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [groupId, setGroupId] = useState();

  const thisEx = exs?.find((ex) => ex?._id === currentEx);

  const initialState = {
    name: thisEx ? thisEx.name : "",
    description: thisEx ? thisEx.description : "",
    instructor: thisEx ? thisEx.instructor : "",
    direction: thisEx ? thisEx.label : "",
  };

  const aims_include = _.filter(aims, function (o) {
    return _.includes(o.list, thisEx?.name);
  });

  const resultArray = aims_include.map((item) => ({
    groupId: item.groupId,
    list: item.list,
  }));

  const data = _.filter(resultArray, function (o) {
    return _.includes(o.list, thisEx.name);
  });

  const data2 = _.uniqBy(data, "groupId");

  useEffect(() => {
    if (!thisEx) {
      navigate("/a-panel/");
    }
  }, [currentEx]);

  useEffect(() => {
    dispatch(getVideo({ groupId: currentEx }));
  }, [currentEx]);

  useEffect(() => {
    if (groupId) {
      dispatch(currentGroupHandler(groupId));
      navigate("/a-panel/group/");
    }
  }, [groupId]);

  const [values, setValues] = useState(initialState);
  const [image, setImage] = useState();
  const [previewURL, setpreviewURL] = useState();
  const [videoDesc, setVideoDesc] = useState();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const filePickerRef = useRef();
  const videoDescPickerRef = useRef();

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const pickVideoDescHandler = () => {
    videoDescPickerRef.current.click();
  };

  const pickedHandler = (e) => {
    let pickedFile;
    if (e.target.files && e.target.files.length === 1) {
      pickedFile = e.target.files[0];
      setImage(pickedFile);
      return;
    }
  };

  const pickedVideoDescHandler = (e) => {
    let pickedVideoDesc;
    if (e.target.files && e.target.files.length === 1) {
      pickedVideoDesc = e.target.files[0];
      setVideoDesc(pickedVideoDesc);
      return;
    }
  };

  useEffect(() => {
    if (!image) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setpreviewURL(fileReader.result);
    };
    fileReader.readAsDataURL(image);
  }, [image]);

  const formData = new FormData();
  formData.append("image", image ? image : "false");
  formData.append("name", values.name);
  formData.append("description", values.description);
  formData.append("exId", currentEx);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(updateEx(formData));
    }
  };

  const formDataVideo = new FormData();
  formDataVideo.append("video", videoDesc ? videoDesc : "false");
  formDataVideo.append("groupId", currentEx); // ! important groupId

  const videoSubmitHandler = () => {
    dispatch(uploadVideo(formDataVideo));
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteEx({ id: currentEx }));
      setTimeout(() => {
        navigate("/a-panel/all_ex/");
      }, 1000);
    }
  };

  const deleteExHandler = () => {
    // dispatch(deleteEx({ id: currentEx }));
    // setTimeout(() => {
    //   navigate("/a-panel/all_ex/");
    // }, 1000);
    setIsConfirmModal(true);
  };

  return (
    <div>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        {isLoading && <Loading2 />}
        {thisEx && !isLoading && (
          <main>
            <div className="header">
              <h4>{thisEx.name}</h4>
              <p className="label">{thisEx.label}</p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="content">
                <div className="infi_1">
                  <div className="in">
                    <label>Название упражнения</label>
                    <InputSmall
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    <img src={`${REACT_APP_SPACE}/${thisEx.image}`} alt="img" />
                    <div className="effect" onClick={pickImageHandler}>
                      <p>новый символ</p>
                    </div>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".img,.png,.jpeg,.jpg"
                      ref={filePickerRef}
                      onChange={pickedHandler}
                    />
                    <img src={previewURL} />
                  </div>
                  <div className="in">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mov,.mp4"
                      ref={videoDescPickerRef}
                      onChange={pickedVideoDescHandler}
                    />
                    <label>Загрузка видео</label>
                    <div className="effect">
                      <p onClick={pickVideoDescHandler}>добавить видео</p>
                    </div>
                    {videoDesc && (
                      <div>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div>
                            <p className="video-name">
                              Имя файла: {videoDesc.name}
                            </p>
                            <p className="video-name">
                              Размер файла:{" "}
                              {_.ceil(videoDesc.size / 1024 / 1024)} Mb{" "}
                            </p>
                            <Button
                              text="Загрузить"
                              onClick={videoSubmitHandler}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-info">
                  <div className="description">
                    <label>Описание</label>
                    <Textarea
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    {thisVideo.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                        id={v._id}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="actions">
                <Button text="Coхранить" type="submit" />
                <Delete text="Удалить" onClick={deleteExHandler} />
              </div>
            </form>

            <div className="groups2">
              <p>Уровни</p>
              {data2.map((d, index) => {
                const thisGroup = groups.find((g) => g._id === d.groupId);
                return (
                  <div
                    key={index}
                    className="group"
                    onClick={() => setGroupId(d.groupId)}
                  >
                    {thisGroup?.name}
                  </div>
                );
              })}
            </div>
          </main>
        )}
      </Wrapper>
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 1rem;
  }
  img {
    margin: 1rem;
    width: 250px;
  }
  .video {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    /* height: 300px; */
  }
  .video-name {
    display: flex;
    align-items: center;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
    div {
      margin: 0 1rem;
    }
  }
  .groups2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 2rem;
    .group {
      margin-left: 1rem;
      color: var(--blue-1);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
    }
    .info_1 {
      width: 400px;
    }
    .description {
      width: 500px;
    }
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
  }

  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: center;
    padding: 0.5rem;
    align-items: center;
    height: 35px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;
export default EditExPage;
