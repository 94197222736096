import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../components/adminNavigations/Navbar";
import Sidebar from "../../components/adminNavigations/Sidebar";
import SubMenu from "../../components/adminNavigations/Submenu";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputSmall from "../../components-special/InputSmall";
import Textarea from "../../components-special/TextArea";
import Loading from "../../components/Loading";
import Video from "../../components/adminGroups/Video";
import Button from "../../components-special/Button";
import Delete from "../../components-special/Delete";
import _ from "lodash";
import {
  getVideo,
  uploadVideo,
  editSong,
  deleteSong,
} from "../../features/adminSlice";
import Loading2 from "../../components/Loading2";
import ConfirmModal from "../../components-special/ConfirmModal";

const EditSongPage = () => {
  const { videos, isLoading, songs, currentSong } = useSelector(
    (store) => store.admin
  );

  let thisVideo;
  thisVideo = _.filter(videos, { groupId: currentSong }); // ! important groupId

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const thisSong = songs.find((song) => song._id === currentSong);

  const initialState = {
    name: thisSong ? thisSong.name : "",
    description: thisSong ? thisSong.description : "",
  };

  useEffect(() => {
    if (!thisSong) {
      navigate("/a-panel/");
    }
  }, [currentSong]);

  useEffect(() => {
    dispatch(getVideo({ groupId: currentSong }));
  }, [currentSong]);

  const [values, setValues] = useState(initialState);
  const [videoDesc, setVideoDesc] = useState();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const videoDescPickerRef = useRef();

  const pickVideoDescHandler = () => {
    videoDescPickerRef.current.click();
  };

  const pickedVideoDescHandler = (e) => {
    let pickedVideoDesc;
    if (e.target.files && e.target.files.length === 1) {
      pickedVideoDesc = e.target.files[0];
      setVideoDesc(pickedVideoDesc);
      return;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, description } = values;
    if (!name || !description) {
      toast.error("Введите все значения");
      return;
    } else {
      dispatch(
        editSong({
          name: values.name,
          description: values.description,
          songId: currentSong,
        })
      );
    }
  };

  const formDataVideo = new FormData();
  formDataVideo.append("video", videoDesc ? videoDesc : "false");
  formDataVideo.append("groupId", currentSong); // ! important groupId

  const videoSubmitHandler = () => {
    dispatch(uploadVideo(formDataVideo));
  };

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const confirmHandler = (c) => {
    if (c === false) {
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(false);
      dispatch(deleteSong({ id: currentSong }));
      setTimeout(() => {
        navigate("/a-panel/all_songs/");
      }, 1000);
    }
  };

  const deleteSongHandler = () => {
    // dispatch(deleteSong({ id: currentSong }));
    // setTimeout(() => {
    //   navigate("/a-panel/all_songs/");
    // }, 1000);
    setIsConfirmModal(true);
  };

  return (
    <div>
      {isConfirmModal && <ConfirmModal passState={confirmHandler} />}
      <Navbar />
      <Sidebar />
      <SubMenu />
      <Wrapper>
        {isLoading && <Loading2 />}

        {thisSong && !isLoading && (
          <main>
            <div className="header">
              <h4>{thisSong.name}</h4>
            </div>
            <form onSubmit={onSubmit}>
              <div className="content">
                <div className="info_1">
                  <div className="in">
                    <label>Название песни/игры </label>
                    <InputSmall
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="in">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mov,.mp4,.wav,.avi,.mp3"
                      ref={videoDescPickerRef}
                      onChange={pickedVideoDescHandler}
                    />
                    <label>Загрузка видео/аудио</label>
                    <div className="effect">
                      <p onClick={pickVideoDescHandler}>добавить видео/аудио</p>
                    </div>
                    {videoDesc && (
                      <div>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div>
                            <p className="video-name">
                              Имя файла: {videoDesc.name}
                            </p>
                            <p className="video-name">
                              Размер файла:{" "}
                              {_.ceil(videoDesc.size / 1024 / 1024)} Mb{" "}
                            </p>
                            <Button
                              text="Загрузить"
                              onClick={videoSubmitHandler}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-info">
                  <div className="description">
                    <label>Описание</label>
                    <Textarea
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="in">
                    {thisVideo.map((v) => (
                      <Video
                        key={v._id}
                        name={v.name}
                        label={v.label}
                        groupId={v.groupId}
                        video={v.video}
                        id={v._id}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="actions">
                <Button text="Coхранить" type="submit" />
                <Delete text="Удалить" onClick={deleteSongHandler} />
              </div>
            </form>
          </main>
        )}
      </Wrapper>
      ;
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
  }
  .info_1 {
    display: flex;
    flex-direction: column;
  }
  .in {
    margin: 1rem;
  }
  label {
    margin-left: 1rem;
    color: var(--clr-grey-3);
  }
  input {
    width: 300px;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 1rem;
  }
  img {
    margin: 1rem;
    width: 250px;
  }
  .video {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    /* height: 300px; */
  }
  .video-name {
    display: flex;
    align-items: center;
    color: var(--green-1);
    margin: 1rem 0;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 1rem;
    margin-bottom: 3rem;
    div {
      margin: 0 1rem;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
    input {
      width: 350px;
    }
  }
  @media (min-width: 992px) {
    .content {
      display: flex;
      flex-direction: row;
    }
    .info_1 {
      width: 400px;
    }

    .description {
      width: 500px;
    }
  }
  @media (min-width: 1200px) {
    .description {
      width: 700px;
    }
  }
  @media (min-width: 1400px) {
    .description {
      width: 800px;
    }
  }

  .effect {
    p,
    svg {
      transition: 0.6s;
    }
    display: flex;
    cursor: pointer;
    justify-content: center;
    padding: 0.5rem;
    align-items: center;
    height: 50px;
    width: 160px;
    z-index: 1;
    position: relative;
    outline: none;
    border: 2px solid var(--green-1);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    transition: color 0.45s ease-in-out;

    ::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--green-1);
      transform-origin: center;
      transform: translate3d(50%, 50%, 0) scale3d(0, 0, 0);
      transition: transform 0.55s ease-in-out;
    }
    :hover {
      p {
        color: white;
      }
      svg {
        color: white;
      }
      :hover::before {
        transform: translate3d(50%, 50%, 0) scale3d(21, 15, 15);
      }
    }
  }
`;
export default EditSongPage;
