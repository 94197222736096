import React from "react";
import styled from "styled-components";
import { deleteAim } from "../../features/adminSlice";
import { useDispatch } from "react-redux";
import Delete from "../../components-special/Delete";
const AimList = ({ name, list, id }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <p className="name">{name}</p>
      <ul>
        {list.map((l, index) => {
          return (
            <div className="list" key={index}>
              <p className="list">{l}</p>
            </div>
          );
        })}
      </ul>
      <Delete text="Удалить" onClick={() => dispatch(deleteAim({ id: id }))} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1rem;
  ul {
    margin: 0.5rem;
    margin-left: 1rem;
  }
  .list {
    display: flex;
    flex-direction: column;
  }
  .name {
    color: var(--blue-0);
    font-size: 1.2rem;
  }
  .list {
    color: var(--clr-grey-3);
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
`;
export default AimList;
