import React from "react";
import styled from "styled-components";
import { logOutUser } from "../../features/user/userSlise";
import { useDispatch, useSelector } from "react-redux";
import { pageIdHandler } from "../../features/user/userSlise";
import { useNavigate } from "react-router-dom";

const Navlinks = () => {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOutHandler = () => {
    dispatch(logOutUser());
  };

  const submenuHandler = (e) => {
    if (!e.target.classList.contains("nav-link")) {
      dispatch(pageIdHandler(null));
    }
  };

  return (
    <Wrapper onMouseOver={submenuHandler}>
      <div className="nav-links">
        {user?.ALevels === true && (
          <button
            className="nav-link"
            onMouseEnter={() => dispatch(pageIdHandler("Уровни"))}
            onClick={() => navigate("/personal-area/groups/")}
          >
            Уровни
          </button>
        )}
        {user?.AExs === true && (
          <button
            className="nav-link"
            onMouseEnter={() => dispatch(pageIdHandler("Упражнения"))}
            onClick={() => navigate("/personal-area/all-exercise/")}
          >
            Упражнения
          </button>
        )}
        {user?.ASongs === true && (
          <button
            className="nav-link"
            onMouseEnter={() => dispatch(pageIdHandler("Песни и игры"))}
            onClick={() => navigate("/personal-area/all_songs/")}
          >
            Песни и игры
          </button>
        )}
        {user?.AThemes === true && (
          <button
            className="nav-link"
            onMouseEnter={() => dispatch(pageIdHandler("Темы"))}
            onClick={() => navigate("/personal-area/all_themes/")}
          >
            Темы
          </button>
        )}
        {user.ABase === true && (
          <button
            className="nav-link"
            onMouseEnter={() => dispatch(pageIdHandler("База знаний"))}
            onClick={() => navigate("/personal-area/all_bases/")}
          >
            База знаний
          </button>
        )}
        <button
          className="nav-link"
          onMouseEnter={() => dispatch(pageIdHandler("Календарь"))}
          onClick={() => navigate("/personal-area/")}
        >
          Календарь
        </button>
        <button className="nav-link" onClick={logOutHandler}>
          Выйти
        </button>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .nav-links {
    display: none;
    align-items: center;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .nav-links {
      display: flex;
      justify-content: center;
      /* ! important */
      align-self: stretch;
    }
    .nav-link {
      border: transparent;
      padding: 0 0.8rem;
      background: transparent;
      cursor: pointer;
      color: white;
      font-size: 0.9rem;
      transition: 0.3s;
      :hover {
        border-bottom: 1px solid white;
      }
    }
  }
  @media (min-width: 1200px) {
    .nav-link {
      padding: 0 1rem;
      font-size: 1.1rem;
    }
  }
  @media (min-width: 1400px) {
  }
`;
export default Navlinks;
